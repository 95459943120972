var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ai-float-button", class: { "chat-mode": _vm.isChatting } },
    [
      _c(
        "div",
        {
          staticClass: "clickable content",
          class: { hide: _vm.isChatting },
          on: { click: _vm.handleChatOpen },
        },
        [
          _c(
            "div",
            { staticClass: "text" },
            _vm._l(
              _vm.constants.AI_FAQ__ASSISTANT_NAME[_vm.gameCodeOnUrl].split(""),
              function (n, i) {
                return _c("span", { key: i, class: `char${i + 1}` }, [
                  _vm._v(_vm._s(n)),
                ])
              }
            ),
            0
          ),
          _vm._v(" "),
          _c("img", {
            staticClass: "ai-img",
            attrs: { src: _vm.constants.AI_FAQ__CHAT_IMG[_vm.gameCodeOnUrl] },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "content",
          style: {
            opacity: _vm.isChatting ? 1 : 0,
            pointerEvents: _vm.isChatting ? "all" : "none",
          },
        },
        [
          _c(
            "div",
            { staticClass: "chat" },
            [
              _c("div", { staticClass: "chat__top" }, [
                _c("div", {
                  staticClass: "chat__top-title",
                  domProps: { innerHTML: _vm._s(_vm.config.title) },
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "chat__top-minimize clickable",
                  on: { click: _vm.onMinimizeClick },
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "chat__top-close clickable",
                  on: { click: _vm.onCloseClick },
                }),
              ]),
              _vm._v(" "),
              _vm.isChatting
                ? _c("ItemMarquee", {
                    staticClass: "chat__marquee",
                    attrs: { text: _vm.config.marquee },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("ChatMessages", {
                ref: "messageList",
                attrs: { messages: _vm.messages },
              }),
              _vm._v(" "),
              _vm.currentReplyIndex === null
                ? _c(
                    "div",
                    { staticClass: "chat__quick-replay" },
                    _vm._l(_vm.config.quickReply, function (r, index) {
                      return _c(
                        "div",
                        {
                          key: `reply-title-${index}`,
                          staticClass: "chat__quick-replay-content clickable",
                          on: {
                            click: function ($event) {
                              return _vm.onQuickReply(r, index)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(r.title) + "\n        "
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : [
                    _c("div", { staticClass: "chat__quick-replay--sort" }, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              _vm.currentReplyIndex = null
                            },
                          },
                        },
                        [_vm._v("✕")]
                      ),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.config.quickReply[_vm.currentReplyIndex].title
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "chat__quick-replay" },
                      _vm._l(
                        _vm.config.quickReply[_vm.currentReplyIndex].children,
                        function (r) {
                          return _c(
                            "div",
                            {
                              key: r.title,
                              staticClass:
                                "chat__quick-replay-content clickable",
                              on: {
                                click: function ($event) {
                                  return _vm.onQuickReply(r)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(r.title) +
                                  "\n          "
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ],
              _vm._v(" "),
              _c("ChatInput", {
                ref: "chatInput",
                attrs: {
                  value: _vm.input,
                  isMessageLoading: _vm.isMessageLoading || _vm.showLoading,
                  inputLocked:
                    _vm.isMaintaining ||
                    _vm.tokenOverLimited ||
                    _vm.showLoading,
                },
                on: {
                  input: function ($event) {
                    _vm.input = $event
                  },
                  onFocus: _vm.onFocus,
                  onBlur: _vm.onBlur,
                  onSend: function ($event) {
                    return _vm.onInputSend(_vm.input)
                  },
                },
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm.showClose
            ? _c("Popup", {
                attrs: {
                  popupContent: _vm.$t("AI_FAQ__POPUP__CLOSE_NOTE"),
                  rate: _vm.rate,
                  endDate: _vm.endDate,
                },
                on: {
                  onEnd: _vm.onEnd,
                  onLinkClose: _vm.onLinkClose,
                  onRateHelpful: _vm.onRateHelpful,
                  onRateUnhelpful: _vm.onRateUnhelpful,
                  onPopupCancel: _vm.onPopupCancel,
                  onPopupConfirm: _vm.onPopupConfirm,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showLoading
        ? _c("div", { staticClass: "ai-loading" }, [
            _c("img", {
              staticClass: "ai-loading-icon",
              attrs: {
                src: `${_vm.CDN}/assets/pc/img/common/loading.gif`,
                alt: "",
              },
            }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }