var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "textarea" }, [
      _c("label", [
        _c("textarea", {
          ref: "input",
          staticClass: "textarea__widget",
          class: { "textarea__widget--error": !_vm.isValid },
          attrs: {
            name: _vm.name,
            placeholder: _vm.placeholder,
            disabled: _vm.isDisabled,
            required: _vm.isRequired,
            maxlength: _vm.isLimitLength && _vm.maxLength,
          },
          domProps: { value: _vm.val },
          on: {
            input: function ($event) {
              return _vm.$emit("input", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "report-form__note" }, [
      _c(
        "div",
        {
          staticClass: "report-form__error",
          class: { invisible: _vm.isValid },
        },
        [_vm._v(_vm._s(_vm.errorMsg))]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isLimitLength,
              expression: "isLimitLength",
            },
          ],
          staticClass: "report-form__length",
        },
        [_vm._v(_vm._s(_vm.curLength) + " / " + _vm._s(_vm.maxLength))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }