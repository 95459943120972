var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-wrap" },
    [
      _c("ticket-step", { attrs: { step: _vm.step } }),
      _vm._v(" "),
      _c("section", { staticClass: "game-section" }, [
        _c("div", { staticClass: "reportlist-main" }, [
          _c("div", { staticClass: "faqdetail-title" }, [
            _c("div", { staticClass: "faqdetail__question" }, [
              _c("div", { staticClass: "faqdetail__question-title" }, [
                _vm._v(
                  _vm._s(_vm.$t("TICKET__TITLE")) +
                    " - " +
                    _vm._s(_vm.currentGameName)
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "faqdetail__question-record" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "faqdetail__question-record-link",
                      attrs: { to: { name: "MobileReportList" } },
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("REPORT__TITLE")) +
                          "\n            "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "reportlist-result-subhead" }, [
              _vm._v(_vm._s(_vm.$t("TICKET__REMINDER"))),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.isTicketTopicCategoryDataReady
        ? [
            _vm.step === 1
              ? [
                  _c(
                    "section",
                    {
                      staticClass: "form-section",
                      class: ["form-section_" + _vm.step],
                    },
                    [
                      _c("form", { staticClass: "report-form" }, [
                        _c("ol", { staticClass: "report-form__ol" }, [
                          _c(
                            "li",
                            { staticClass: "report-form__row" },
                            [
                              _c("div", { staticClass: "report-form__label" }, [
                                _vm._v(_vm._s(_vm.$t("TICKET__QUESTION_1"))),
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.baseCategoriesToDisplay,
                                function (category) {
                                  return _c("custom-radio", {
                                    key: category.id,
                                    attrs: {
                                      label: category.name,
                                      name: "base_category",
                                      "radio-value": category.id,
                                    },
                                    model: {
                                      value: _vm.userCheckedBaseCategoryId,
                                      callback: function ($$v) {
                                        _vm.userCheckedBaseCategoryId = $$v
                                      },
                                      expression: "userCheckedBaseCategoryId",
                                    },
                                  })
                                }
                              ),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.userCheckedBaseCategoryId
                            ? _c(
                                "li",
                                { staticClass: "report-form__row" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "report-form__label" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("TICKET__QUESTION_2"))
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.currentTicketTopicCategories,
                                    function (ttc) {
                                      return _c("custom-radio", {
                                        key: ttc.id,
                                        attrs: {
                                          label: ttc.name,
                                          name: "ticket_topic_category",
                                          "radio-value": ttc.id,
                                        },
                                        model: {
                                          value:
                                            _vm.userCheckedTicketTopicCategoryId,
                                          callback: function ($$v) {
                                            _vm.userCheckedTicketTopicCategoryId =
                                              $$v
                                          },
                                          expression:
                                            "userCheckedTicketTopicCategoryId",
                                        },
                                      })
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "reportlist__return",
                      attrs: { disabled: !_vm.isFirstButtonEnabled },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.lockTicketTopicCategoryId.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("GENERAL__BUTTON_NEXT_STEP")) +
                          "\n        "
                      ),
                      _vm.isCoolingDown
                        ? _c("vue-countdown", {
                            attrs: { time: 10000 },
                            on: { end: _vm.handleCooldownEnd },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (props) {
                                    return [
                                      _vm._v(
                                        "(" + _vm._s(props.totalSeconds) + ")"
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1521751193
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.step === 2
              ? [
                  _c("section", { staticClass: "form-section" }, [
                    _c("form", { staticClass: "report-form" }, [
                      _c("div", { staticClass: "report-form__content" }, [
                        _c("div", { staticClass: "report-form__label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("TICKET__FIELD_TICKET_TOPIC_CATEGORY")
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "report-form__field" },
                          [
                            _c("custom-input", {
                              attrs: {
                                value: _vm.currentTicketTopicCategoryName,
                                isDisabled: true,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "report-form__content" }, [
                        _c("div", { staticClass: "report-form__label" }, [
                          _vm._v(_vm._s(_vm.$t("TICKET__FIELD_TICKET_TOPIC"))),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "report-form__field" }, [
                          _c(
                            "div",
                            { staticClass: "select-wrap" },
                            [
                              _c("custom-select", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "GENERAL__PLACEHOLDER_DEFAULT_SELECT"
                                  ),
                                  options: _vm.ticketTopicIdMapping,
                                },
                                model: {
                                  value: _vm.userSelectedTicketTopicId,
                                  callback: function ($$v) {
                                    _vm.userSelectedTicketTopicId = $$v
                                  },
                                  expression: "userSelectedTicketTopicId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "reportlist__return",
                      attrs: { disabled: !_vm.isSecondButtonEnabled },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.lockTicketTopicId.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("GENERAL__BUTTON_NEXT_STEP")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "reportlist__back",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.unlockTicketTopicCategoryId.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("GENERAL__BUTTON_PREVIOUS_STEP")) +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.step === 3
              ? [
                  _vm.shouldShowMyinfo
                    ? _c(
                        "button",
                        {
                          staticClass: "reportlist__back",
                          on: { click: _vm.getMyinfo },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("GENERAL__BUTTON_MYINFO")) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("section", { staticClass: "form-section" }, [
                    _c(
                      "form",
                      { staticClass: "report-form" },
                      [
                        _vm._l(_vm.currentTicketTopicFields, function (field) {
                          return [
                            _c(
                              "div",
                              {
                                key: field.id,
                                staticClass: "report-form__content",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "report-form__inline" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "report-form__label" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(field.displayName) +
                                            "\n                  "
                                        ),
                                        field.isRequired
                                          ? _c("span", [_vm._v("*")])
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "report-form__field" },
                                      [
                                        field.type === "input"
                                          ? [
                                              _c("custom-input", {
                                                attrs: {
                                                  name: field.name,
                                                  placeholder:
                                                    field.attributes
                                                      .placeholder,
                                                  "is-required":
                                                    field.isRequired,
                                                  "error-msg": _vm.$t(
                                                    "GENERAL__INPUT_ERROR_REQUIRED"
                                                  ),
                                                  "is-limit-length": true,
                                                  readonly:
                                                    _vm.canInjectKeys.indexOf(
                                                      field.name
                                                    ) >= 0,
                                                },
                                                model: {
                                                  value:
                                                    _vm.fieldsValue[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.fieldsValue,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "fieldsValue[field.name]",
                                                },
                                              }),
                                            ]
                                          : field.type === "textarea"
                                          ? [
                                              _c("custom-textarea", {
                                                attrs: {
                                                  name: field.name,
                                                  placeholder:
                                                    field.attributes
                                                      .placeholder,
                                                  "is-required":
                                                    field.isRequired,
                                                  "error-msg": _vm.$t(
                                                    "GENERAL__INPUT_ERROR_REQUIRED"
                                                  ),
                                                  "is-limit-length": true,
                                                },
                                                model: {
                                                  value:
                                                    _vm.fieldsValue[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.fieldsValue,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "fieldsValue[field.name]",
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.errors && _vm.errors[field.name]
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "report-form__error",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      field.attributes.error
                                                    ) + "。"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                        _vm._v(" "),
                                        field.type === "file"
                                          ? [
                                              _c("uploader", {
                                                attrs: {
                                                  name: field.name,
                                                  label: _vm.$t(
                                                    "GENERAL__BUTTON_UPLOAD_FILE"
                                                  ),
                                                  "is-required":
                                                    field.isRequired,
                                                },
                                                on: {
                                                  uploading:
                                                    _vm.updateUploadingFiles,
                                                  uploaded:
                                                    _vm.addUploadedFileIds,
                                                  removed:
                                                    _vm.removeUploadedFileIds,
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "reportlist__return",
                      attrs: { disabled: !_vm.isThirdButtonEnabled },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.lockForm.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("GENERAL__BUTTON_NEXT_STEP")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "reportlist__back",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.unlockTicketTopicId.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("GENERAL__BUTTON_PREVIOUS_STEP")) +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.step === 4
              ? [
                  _c("section", { staticClass: "form-section" }, [
                    _c(
                      "form",
                      { staticClass: "report-form" },
                      [
                        _c(
                          "div",
                          { staticClass: "report-form__content" },
                          [
                            _c("custom-checkbox", {
                              attrs: {
                                name: "ticket_email_checkbox",
                                label: _vm.$t("TICKET__EMAIL_FIELD_CHECKBOX"),
                              },
                              model: {
                                value: _vm.isEmailRequired,
                                callback: function ($$v) {
                                  _vm.isEmailRequired = $$v
                                },
                                expression: "isEmailRequired",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.isEmailRequired
                          ? [
                              _c(
                                "div",
                                { staticClass: "report-form__content" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "report-form__label" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("TICKET__EMAIL")) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "report-form__field" },
                                    [
                                      _c("custom-input", {
                                        attrs: {
                                          name: "notification_email_address",
                                          placeholder: _vm.$t(
                                            "GENERAL__INPUT_PLACEHOLDER_HALFWIDTH"
                                          ),
                                          "custom-type":
                                            _vm.constants.GENERAL__INPUT_TYPES
                                              .EMAIL,
                                          "error-msg": _vm.$t(
                                            "GENERAL__INPUT_ERROR_EMAIL_FORMAT"
                                          ),
                                        },
                                        model: {
                                          value: _vm.emailAddress,
                                          callback: function ($$v) {
                                            _vm.emailAddress = $$v
                                          },
                                          expression: "emailAddress",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "report-form__content" },
                          [
                            _c("custom-checkbox", {
                              attrs: {
                                name: "ticket_tos_checkbox",
                                label: _vm.$t("TICKET__TOS_CHECKBOX"),
                              },
                              model: {
                                value: _vm.isTosChecked,
                                callback: function ($$v) {
                                  _vm.isTosChecked = $$v
                                },
                                expression: "isTosChecked",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "reportlist__return",
                      attrs: { disabled: !_vm.isFourthButtonEnabled },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.submitTicket.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("GENERAL__BUTTON_SUBMIT_FORM")) +
                          "\n        "
                      ),
                      _vm.isSubmitting
                        ? _c("vue-countdown", {
                            attrs: { time: 10000 },
                            on: { end: _vm.handleCountdownEnd },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (props) {
                                    return [
                                      _vm._v(
                                        "(" + _vm._s(props.totalSeconds) + ")"
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1521751193
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "reportlist__back",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.unlockForm.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("GENERAL__BUTTON_PREVIOUS_STEP")) +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
          ]
        : [
            _c("div", { staticClass: "loading" }, [
              _c("img", {
                staticClass: "icon-loading",
                attrs: {
                  src: `${_vm.CDN}/assets/pc/img/common/loading.gif`,
                  alt: "",
                },
              }),
            ]),
          ],
      _vm._v(" "),
      _vm.isShowingConfirmLeaving
        ? _c("confirm", {
            attrs: {
              title: _vm.$t("POPUP__ALERT"),
              content: _vm.$t("POPUP__CONFIRM_LEAVE"),
            },
            on: { close: _vm.closeConfirmLeaving, confirm: _vm.changePage },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }