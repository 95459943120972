var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-wrap" }, [
    _c("div", { staticClass: "main-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("navigation", { attrs: { title: _vm.$t("APPLICATION__TITLE") } }),
        _vm._v(" "),
        _c("div", { staticClass: "main-inner" }, [
          _c(
            "div",
            { staticClass: "reportlist" },
            [
              _c("breadcrumb", { attrs: { paths: _vm.paths } }),
              _vm._v(" "),
              _c("div", { staticClass: "onlineservice-list__tab" }, [
                _c(
                  "div",
                  {
                    staticClass: "onlineservice-list__tablink",
                    class: {
                      "onlineservice-list__tablink--active":
                        _vm.currentApplicationType ===
                        _vm.constants.APPLICATION__TYPES.VERIFICATION,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.switchApplicationType(
                          _vm.constants.APPLICATION__TYPES.VERIFICATION
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("APPLICATION__TAB_VERIFICATION")) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "onlineservice-list__tablink",
                    class: {
                      "onlineservice-list__tablink--active":
                        _vm.currentApplicationType ===
                        _vm.constants.APPLICATION__TYPES.CANT_LOGIN,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.switchApplicationType(
                          _vm.constants.APPLICATION__TYPES.CANT_LOGIN
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("APPLICATION__TAB_CANT_LOGIN")) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "onlineservice-list__tablink",
                    class: {
                      "onlineservice-list__tablink--active":
                        _vm.currentApplicationType ===
                        _vm.constants.APPLICATION__TYPES.OTHERS,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.switchApplicationType(
                          _vm.constants.APPLICATION__TYPES.OTHERS
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("APPLICATION__TAB_OTHERS")) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("keep-alive", [
                _c(
                  "div",
                  { staticClass: "onlineservicelist-main" },
                  [
                    _c("div", { staticClass: "onlineservicelist-table" }, [
                      _c(
                        "table",
                        { staticClass: "onlineservicelist-table__inner" },
                        [
                          _c("thead", [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticClass:
                                    "onlineservicelist-table__th onlineservicelist-table__date",
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        _vm.$t("APPLICATION__RECORD_TIME")
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  staticClass:
                                    "onlineservicelist-table__th onlineservicelist-table__item",
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        _vm.$t("APPLICATION__RECORD_ITEM")
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  staticClass:
                                    "onlineservicelist-table__th onlineservicelist-table__status",
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        _vm.$t("APPLICATION__RECORD_STATUS")
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.showApplicationNotes
                                ? _c(
                                    "th",
                                    {
                                      staticClass:
                                        "onlineservicelist-table__th onlineservicelist-table__note",
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.$t("APPLICATION__RECORD_NOTES")
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.applications && _vm.applications.length > 0
                            ? [
                                _c(
                                  "tbody",
                                  _vm._l(_vm.applications, function (app) {
                                    return _c(
                                      "tr",
                                      {
                                        key: app.appCsNumber,
                                        staticClass:
                                          "onlineservicelist-table__tr",
                                      },
                                      [
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "onlineservicelist-table__td onlineservicelist-table__date",
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.utils.formatTimestampToString(
                                                    app.createdAt,
                                                    _vm.$t(
                                                      "GENERAL__DATETIME_FORMAT"
                                                    )
                                                  )
                                                ) +
                                                "\n                      "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "onlineservicelist-table__td onlineservicelist-table__item",
                                          },
                                          _vm._l(
                                            app.types,
                                            function (type, idx) {
                                              return _c("span", { key: idx }, [
                                                _vm._v(
                                                  "\n                          " +
                                                    _vm._s(
                                                      _vm.getApplicationTypeByValue(
                                                        type
                                                      )
                                                    ) +
                                                    "\n                          "
                                                ),
                                                idx < app.types.length - 1
                                                  ? _c("span", [_vm._v(",")])
                                                  : _vm._e(),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "onlineservicelist-table__td onlineservicelist-table__status",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                class: {
                                                  "onlineservicelist-table__statusval":
                                                    _vm.isApplicationRejected(
                                                      app.verificationStatus
                                                    ),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                          " +
                                                    _vm._s(
                                                      _vm.getApplicationStatusByValue(
                                                        app.verificationStatus
                                                      )
                                                    ) +
                                                    "\n                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.showApplicationNotes
                                          ? _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "onlineservicelist-table__td onlineservicelist-table__notes",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(app.notes)),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                    _vm._v(" "),
                    !_vm.applications || _vm.applications.length === 0
                      ? [
                          _c(
                            "div",
                            { staticClass: "onlineservicelist-empty" },
                            [
                              _c("div", {
                                staticClass:
                                  "onlineservicelist-empty__icon onlineservicelist-empty__icon--failure",
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "onlineservicelist-empty__title",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t("APPLICATION__NO_RECORD")) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }