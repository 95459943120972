var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-wrap" },
    [
      _vm.news
        ? [
            _c("section", { staticClass: "game-section" }, [
              _c("div", { staticClass: "cantlogin-list__main" }, [
                _c("div", { staticClass: "faqdetail-title" }, [
                  _c("div", { staticClass: "faqdetail__question" }, [
                    _vm._v(_vm._s(_vm.news.title)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "faqdetail__content" }, [
                _c("div", {
                  staticClass: "ip-solution__txt",
                  domProps: { innerHTML: _vm._s(_vm.news.content) },
                }),
              ]),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }