var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-wrap main-wrap--hassearch" }, [
    _c("div", { staticClass: "main-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("navigation", {
          attrs: {
            title: _vm.$t("GENERAL__TITLE"),
            "has-search-bar": true,
            "search-type": _vm.searchType,
            "search-code": _vm.searchCode,
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "main-inner" }, [
          _c(
            "div",
            { staticClass: "search" },
            [
              _c("breadcrumb", { attrs: { paths: _vm.paths } }),
              _vm._v(" "),
              _c("div", { staticClass: "main-title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("SEARCH__RESULT_TITLE")) +
                    "\n          "
                ),
                _c("span", { staticClass: "search__keyword" }, [
                  _vm._v(_vm._s(_vm.searchString)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "search-main" }, [
                _c(
                  "div",
                  { staticClass: "search-result" },
                  [
                    _vm.searchResults && _vm.searchResults.length > 0
                      ? _vm._l(_vm.searchResults, function (result) {
                          return _c(
                            "dl",
                            {
                              key: result.id,
                              ref: "searchResult",
                              refInFor: true,
                              staticClass: "search-result__item",
                              on: {
                                click: function ($event) {
                                  return _vm.toArticle(result.id)
                                },
                              },
                            },
                            [
                              _c("dt", { staticClass: "search-result__q" }, [
                                _c(
                                  "div",
                                  { staticClass: "search-result__prefix" },
                                  [_vm._v("Q:")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "search-result__main" },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(result.title) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("dd", { staticClass: "search-result__a" }, [
                                _c(
                                  "div",
                                  { staticClass: "search-result__prefix" },
                                  [_vm._v("A:")]
                                ),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass:
                                    "search-result__main search-result__main__a",
                                  domProps: {
                                    innerHTML: _vm._s(result.content),
                                  },
                                }),
                              ]),
                            ]
                          )
                        })
                      : _vm.searchResults
                      ? [
                          _c("div", { staticClass: "search-result__a" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("SEARCH__NO_RESULT")) +
                                "\n              "
                            ),
                          ]),
                        ]
                      : [
                          _c("div", { staticClass: "loading" }, [
                            _c("img", {
                              staticClass: "icon-loading",
                              attrs: {
                                src: `${_vm.CDN}/assets/pc/img/common/loading.gif`,
                                alt: "",
                              },
                            }),
                          ]),
                        ],
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }