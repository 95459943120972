var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home-news" },
    [
      _c("div", { staticClass: "home-section__title" }, [
        _c("div", {
          staticClass:
            "home-section__title-icon home-section__title-icon--news",
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "home-section__title-txt" }, [
          _vm._v("\n      " + _vm._s(_vm.$t("HOME__NEWS_TITLE")) + "\n    "),
        ]),
      ]),
      _vm._v(" "),
      _vm.topNews
        ? _c(
            "ul",
            { staticClass: "home-news__entry" },
            _vm._l(_vm.topNews, function (news) {
              return _c(
                "li",
                { key: news.id, staticClass: "home-news__entry-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "home-news__entry-link",
                      attrs: { to: { name: "News", params: { id: news.id } } },
                    },
                    [
                      _c("div", { staticClass: "home-news__entry-icon" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "home-news__entry-txt" }, [
                        _vm._v(
                          "\n          " + _vm._s(news.title) + "\n        "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "router-link",
        { staticClass: "home-news__more", attrs: { to: { name: "NewsList" } } },
        [
          _c("div", { staticClass: "home-news__more-txt" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("HOME__NEWS_LEARN_MORE")) + "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "home-news__more-icon" }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }