var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "messageList", staticClass: "chat__body" },
    _vm._l(_vm.messages, function (m, i) {
      return _c(
        "div",
        { key: i, staticClass: "chat__body-row", class: m.chatRole },
        [
          m.chatRole === _vm.constants.AI_FAQ__CHAT_ROLE.SYSTEM
            ? _c("div", { staticClass: "chat__body-system" }, [
                _vm._v(_vm._s(m.message)),
              ])
            : [
                _c("div", { staticClass: "chat__body-img" }, [
                  _c("img", {
                    attrs: {
                      src:
                        m.chatRole === _vm.constants.AI_FAQ__CHAT_ROLE.USER
                          ? _vm.userInfo.avatar
                          : _vm.constants.AI_FAQ__CHAT_IMG[_vm.gameCodeOnUrl],
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "chat__body-wrapper" }, [
                  m.chatRole !== _vm.constants.AI_FAQ__CHAT_ROLE.LOADING
                    ? _c("div", {
                        staticClass: "chat__body-message",
                        class: m.chatRole,
                        attrs: { readonly: "" },
                        domProps: {
                          innerHTML: _vm._s(_vm.generateMessage(m.message)),
                        },
                      })
                    : _c("div", { staticClass: "chat__body-message" }, [
                        _vm._m(0, true),
                      ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "chat__body-ts" }, [
                    _c("span", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.generateTime(m)) +
                          "\n          "
                      ),
                    ]),
                  ]),
                ]),
              ],
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lds-ellipsis" }, [
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }