var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-wrap" }, [
    _c("div", { staticClass: "main-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("navigation", {
          attrs: { title: _vm.$t("GENERAL__TITLE"), "has-search-bar": false },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "main-inner" }, [
          _c(
            "div",
            { staticClass: "login" },
            [
              _c("breadcrumb", { attrs: { paths: _vm.paths } }),
              _vm._v(" "),
              _c("div", { staticClass: "login-main" }, [
                _c("div", { staticClass: "login-title" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("LOGIN__CHOOSE_PLATFORM")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "login-inner" },
                  _vm._l(_vm.platformIds, function (platformId) {
                    return _c(
                      "div",
                      { key: platformId, staticClass: "login-icon" },
                      [
                        _c("img", {
                          attrs: {
                            src: `${_vm.CDN}/assets/share/img/icon-circle-platform-${platformId}.png`,
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toLogin(platformId)
                            },
                          },
                        }),
                        _vm._v(" "),
                        platformId ===
                        _vm.constants.GENERAL__GOP_PLATFORM.GARENA
                          ? _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.switchLoginPopup(false)
                                  },
                                },
                              },
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "CantLogin" } } },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.$t("POPUP__CANT_LOGIN")) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }