var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-wrap" },
    [
      _vm.isDataReady
        ? [
            _c("section", { staticClass: "form-section" }, [
              _c("div", { staticClass: "reportlist-main" }, [
                _c("div", { staticClass: "faqdetail-title" }, [
                  _c("div", { staticClass: "faqdetail__question" }, [
                    _vm._v(_vm._s(_vm.$t("VERIFICATION__RESET_PROFILE_TITLE"))),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "reportlist-result-subhead" }, [
                    _c("dl", { staticClass: "profile-warning" }, [
                      _c("dt", { staticClass: "profile-warning__title" }, [
                        _vm._v(_vm._s(_vm.$t("PROFILE__DESCRIPTION"))),
                      ]),
                      _vm._v(" "),
                      _c("dd", [
                        _c("ol", { staticClass: "profile-warning__list" }, [
                          _c("li", { staticClass: "profile-warning__item" }, [
                            _vm._v(_vm._s(_vm.$t("PROFILE__WARNING_1"))),
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "profile-warning__item" }, [
                            _vm._v(_vm._s(_vm.$t("PROFILE__WARNING_2"))),
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "profile-warning__item" }, [
                            _vm._v(_vm._s(_vm.$t("PROFILE__WARNING_3"))),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "form",
                { staticClass: "report-form" },
                [
                  _c("div", { staticClass: "report-form__content" }, [
                    _c("div", { staticClass: "report-form__label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "VERIFICATION__RESET_PROFILE_APPLICATION_SERVICE"
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("ol", { staticClass: "report-form__ol" }, [
                    _c(
                      "li",
                      { staticClass: "report-form__row" },
                      [
                        _vm.verificationTypes.includes(
                          _vm.constants.APPLICATION__VERIFICATION_TYPES
                            .CANCEL_PHONE
                        )
                          ? [
                              _c("custom-checkbox", {
                                attrs: {
                                  name: "verification_service_phone",
                                  label: _vm.$t(
                                    "VERIFICATION__RESET_PROFILE_APPLICATION_CANCEL_PHONE"
                                  ),
                                },
                                model: {
                                  value: _vm.hasCancelPhoneChecked,
                                  callback: function ($$v) {
                                    _vm.hasCancelPhoneChecked = $$v
                                  },
                                  expression: "hasCancelPhoneChecked",
                                },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.verificationTypes.includes(
                          _vm.constants.APPLICATION__VERIFICATION_TYPES
                            .CANCEL_EMAIL
                        )
                          ? [
                              _c("custom-checkbox", {
                                attrs: {
                                  name: "verification_service_email",
                                  label: _vm.$t(
                                    "VERIFICATION__RESET_PROFILE_APPLICATION_CANCEL_EMAIL"
                                  ),
                                },
                                model: {
                                  value: _vm.hasCancelEmailChecked,
                                  callback: function ($$v) {
                                    _vm.hasCancelEmailChecked = $$v
                                  },
                                  expression: "hasCancelEmailChecked",
                                },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.verificationTypes.includes(
                          _vm.constants.APPLICATION__VERIFICATION_TYPES
                            .RESET_PASSWORD
                        )
                          ? [
                              _c("custom-checkbox", {
                                attrs: {
                                  name: "verification_service_pwd",
                                  label: _vm.$t(
                                    "VERIFICATION__RESET_PROFILE_APPLICATION_RESET_PASSWORD"
                                  ),
                                },
                                model: {
                                  value: _vm.hasResetPasswordChecked,
                                  callback: function ($$v) {
                                    _vm.hasResetPasswordChecked = $$v
                                  },
                                  expression: "hasResetPasswordChecked",
                                },
                              }),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.hasResetPasswordChecked
                    ? [
                        _c("div", { staticClass: "report-form__content" }, [
                          _c("div", { staticClass: "report-form__label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "VERIFICATION__RESET_PROFILE_APPLICATION_NOTIFY"
                                )
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("ol", { staticClass: "report-form__ol" }, [
                          false
                            ? _c(
                                "li",
                                { staticClass: "report-form__row" },
                                [
                                  _c("custom-radio", {
                                    attrs: {
                                      name: "notification_type",
                                      label: _vm.$t(
                                        "VERIFICATION__RESET_PROFILE_APPLICATION_NOTIFY_SMS"
                                      ),
                                      "radio-value":
                                        _vm.constants
                                          .APPLICATION__VERIFICATION_NOTIFICATION_TYPES
                                          .SMS,
                                    },
                                    model: {
                                      value: _vm.notificationType,
                                      callback: function ($$v) {
                                        _vm.notificationType = $$v
                                      },
                                      expression: "notificationType",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.isNotifyBySms
                                    ? [
                                        _c("phone-input", {
                                          attrs: {
                                            name: "notification_info",
                                            placeholder: _vm.$t(
                                              "GENERAL__INPUT_PLACEHOLDER_HALFWIDTH_INT"
                                            ),
                                            "error-msg": _vm.$t(
                                              "GENERAL__INPUT_ERROR_PHONE_FORMAT"
                                            ),
                                          },
                                          model: {
                                            value: _vm.notificationInfo,
                                            callback: function ($$v) {
                                              _vm.notificationInfo = $$v
                                            },
                                            expression: "notificationInfo",
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "li",
                            { staticClass: "report-form__row" },
                            [
                              false
                                ? _c("custom-radio", {
                                    attrs: {
                                      name: "notification_type",
                                      label: _vm.$t(
                                        "VERIFICATION__RESET_PROFILE_APPLICATION_NOTIFY_EMAIL"
                                      ),
                                      "radio-value":
                                        _vm.constants
                                          .APPLICATION__VERIFICATION_NOTIFICATION_TYPES
                                          .EMAIL,
                                    },
                                    model: {
                                      value: _vm.notificationType,
                                      callback: function ($$v) {
                                        _vm.notificationType = $$v
                                      },
                                      expression: "notificationType",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isNotifyByEmail
                                ? [
                                    _c("custom-input", {
                                      attrs: {
                                        name: "notification_info",
                                        placeholder: _vm.$t(
                                          "GENERAL__INPUT_PLACEHOLDER_HALFWIDTH"
                                        ),
                                        "custom-type":
                                          _vm.constants.GENERAL__INPUT_TYPES
                                            .EMAIL,
                                        "error-msg": _vm.$t(
                                          "GENERAL__INPUT_ERROR_EMAIL_FORMAT"
                                        ),
                                      },
                                      model: {
                                        value: _vm.notificationInfo,
                                        callback: function ($$v) {
                                          _vm.notificationInfo = $$v
                                        },
                                        expression: "notificationInfo",
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "report-form__content report-form__content-files",
                    },
                    [
                      _c("uploader", {
                        attrs: {
                          label: _vm.$t("GENERAL__BUTTON_UPLOAD_ID"),
                          "show-notice": false,
                          "is-required": true,
                          "is-img-only": true,
                          "max-file-cnt":
                            _vm.constants.GENERAL__MAX_FILE_CNT.APPLICATION,
                          "error-msg": _vm.$t(
                            "GENERAL__INPUT_ERROR_FILE_NOT_SELECTED"
                          ),
                          name: "files",
                        },
                        on: {
                          uploading: _vm.updateUploadingFiles,
                          uploaded: _vm.addUploadedFileIds,
                          removed: _vm.removeUploadedFileIds,
                        },
                      }),
                      _vm._v(" "),
                      _c("ol", {
                        staticClass: "report-form__uploader-info",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "CANT_LOGIN__ACCOUNT_RECOVERY_UPLOAD_NOTICE_1"
                            )
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "bg-grey" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "report-form__content report-form__content-tos",
                },
                [
                  _c("custom-checkbox", {
                    attrs: {
                      name: "ticket_tos_checkbox",
                      label: _vm.$t("TICKET__TOS_CHECKBOX"),
                    },
                    model: {
                      value: _vm.isTosChecked,
                      callback: function ($$v) {
                        _vm.isTosChecked = $$v
                      },
                      expression: "isTosChecked",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "reportlist__return",
                attrs: { disabled: !_vm.isButtonEnabled },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.submitApplication.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("GENERAL__BUTTON_SUBMIT_FORM")) +
                    "\n      "
                ),
                _vm.isSubmitting
                  ? _c("vue-countdown", {
                      attrs: { time: 10000 },
                      on: { end: _vm.handleCountdownEnd },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (props) {
                              return [
                                _vm._v("(" + _vm._s(props.totalSeconds) + ")"),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1521751193
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        : [
            _c("div", { staticClass: "loading" }, [
              _c("img", {
                staticClass: "icon-loading",
                attrs: {
                  src: `${_vm.CDN}/assets/pc/img/common/loading.gif`,
                  alt: "",
                },
              }),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }