var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPopup
    ? _c("div", { staticClass: "notification-wrap" }, [
        _c("div", { staticClass: "notification-dimmer" }),
        _vm._v(" "),
        _c("div", { staticClass: "notification" }, [
          _c("div", { staticClass: "notification-inner" }, [
            _c("div", { staticClass: "notification__title" }, [
              _c("div", { staticClass: "notification__title-icon" }),
              _vm._v(" "),
              _c("div", { staticClass: "notification__title-txt" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.popupContent.title) + "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "notification__main" }, [
              _c("div", { staticClass: "notification__content" }, [
                _c("div", {
                  staticClass: "notification__content-txt",
                  domProps: { innerHTML: _vm._s(_vm.popupContent.content) },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "rountbutton",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.onClickButton.apply(null, arguments)
                  },
                },
              },
              [
                _c("button", { staticClass: "rountbutton__widget" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("GENERAL__BUTTON_NOTIFICATION")) +
                      "\n        "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("a", {
              staticClass: "notification__close",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.closePopup.apply(null, arguments)
                },
              },
            }),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }