var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "main-wrap",
      class: { "bg-white": _vm.isApplicationListEmpty },
    },
    [
      _c(
        "section",
        { staticClass: "game-section" },
        [
          _c("div", { staticClass: "onlineservice-list__tab" }, [
            _c(
              "div",
              {
                staticClass: "onlineservice-list__tablink",
                class: {
                  "onlineservice-list__tablink--active":
                    _vm.currentApplicationType ===
                    _vm.constants.APPLICATION__TYPES.VERIFICATION,
                },
                on: {
                  click: function ($event) {
                    return _vm.switchApplicationType(
                      _vm.constants.APPLICATION__TYPES.VERIFICATION
                    )
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("APPLICATION__TAB_VERIFICATION")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "onlineservice-list__tablink",
                class: {
                  "onlineservice-list__tablink--active":
                    _vm.currentApplicationType ===
                    _vm.constants.APPLICATION__TYPES.CANT_LOGIN,
                },
                on: {
                  click: function ($event) {
                    return _vm.switchApplicationType(
                      _vm.constants.APPLICATION__TYPES.CANT_LOGIN
                    )
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("APPLICATION__TAB_CANT_LOGIN")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "onlineservice-list__tablink",
                class: {
                  "onlineservice-list__tablink--active":
                    _vm.currentApplicationType ===
                    _vm.constants.APPLICATION__TYPES.OTHERS,
                },
                on: {
                  click: function ($event) {
                    return _vm.switchApplicationType(
                      _vm.constants.APPLICATION__TYPES.OTHERS
                    )
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("APPLICATION__TAB_OTHERS")) +
                    "\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("hr", {
            staticClass: "onlineservice-list__tab_underline",
            class: `onlineservice-list__tab_underline--${_vm.currentApplicationType}`,
          }),
          _vm._v(" "),
          _vm.applications && !_vm.utils.isEmptyArray(_vm.applications)
            ? _vm._l(_vm.applications, function (app) {
                return _c(
                  "div",
                  {
                    key: app.appCsNumber,
                    staticClass: "onlineservice-list__main",
                  },
                  [
                    _c("div", { staticClass: "onlineservice-list__content" }, [
                      _c("div", { staticClass: "onlineservice-list__row" }, [
                        _c(
                          "div",
                          { staticClass: "onlineservice-list__title" },
                          [_vm._v(_vm._s(_vm.$t("APPLICATION__RECORD_TIME")))]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "onlineservice-list__item" }, [
                          _vm._v(
                            _vm._s(
                              _vm.utils.formatTimestampToString(
                                app.createdAt,
                                _vm.$t("GENERAL__DATETIME_FORMAT")
                              )
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "onlineservice-list__row" }, [
                        _c(
                          "div",
                          { staticClass: "onlineservice-list__title" },
                          [_vm._v(_vm._s(_vm.$t("APPLICATION__RECORD_ITEM")))]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "onlineservice-list__item" },
                          _vm._l(app.types, function (type, idx) {
                            return _c("span", { key: idx }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.getApplicationTypeByValue(type)) +
                                  "\n                "
                              ),
                              idx < app.types.length - 1
                                ? _c("span", [_c("br")])
                                : _vm._e(),
                            ])
                          }),
                          0
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "onlineservice-list__row" }, [
                        _c(
                          "div",
                          { staticClass: "onlineservice-list__title" },
                          [_vm._v(_vm._s(_vm.$t("APPLICATION__RECORD_STATUS")))]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "onlineservice-list__item",
                            class: {
                              "onlineservice-list__item--ng":
                                _vm.constants
                                  .APPLICATION__VERIFICATION_STATUS_MAPPING[
                                  app.verificationStatus
                                ] === "NG",
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.getApplicationStatusByValue(
                                    app.verificationStatus
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.showApplicationNotes
                        ? _c(
                            "div",
                            { staticClass: "onlineservice-list__row" },
                            [
                              _c(
                                "div",
                                { staticClass: "onlineservice-list__title" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("APPLICATION__RECORD_NOTES"))
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "onlineservice-list__item onlineservice-list__notes",
                                },
                                [_c("span", [_vm._v(_vm._s(app.notes))])]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                )
              })
            : _vm.isApplicationListEmpty
            ? [
                _c("div", { staticClass: "reportlist-main" }, [
                  _c("div", { staticClass: "reportlist-inner" }, [
                    _c("div", {
                      staticClass:
                        "reportlist__inner-icon reportlist__inner-icon--2",
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "reportlist__inner-txt" }, [
                      _vm._v(_vm._s(_vm.$t("APPLICATION__NO_RECORD"))),
                    ]),
                  ]),
                ]),
              ]
            : [
                _c("div", { staticClass: "reportlist__nomore" }, [
                  _c("img", {
                    staticClass: "icon-loading",
                    attrs: {
                      src: `${_vm.CDN}/assets/pc/img/common/loading.gif`,
                      alt: "",
                    },
                  }),
                ]),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }