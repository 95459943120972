var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ga_footer" }, [
    _c("div", { staticClass: "fullc" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "right-fmenu" }, [
        _c("ul", [
          _c("li", { staticClass: "terms-tos" }, [
            _c(
              "a",
              { attrs: { href: _vm.$t("TERMS__TOS_LINK"), target: "_blank" } },
              [_vm._v(_vm._s(_vm.$t("TERMS__TOS")))]
            ),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "terms-pp" }, [
            _c(
              "a",
              { attrs: { href: _vm.$t("TERMS__PP_LINK"), target: "_blank" } },
              [_vm._v(_vm._s(_vm.$t("TERMS__PP")))]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "left-copyr", attrs: { id: "destop_copyr" } },
      [
        _c("p", { staticClass: "copyright text-center" }, [
          _vm._v(
            "\n        Copyright © Garena Online. Trademarks belong to their respective owners. All rights reserved.\n      "
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }