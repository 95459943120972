var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-wrap" }, [
    _c("div", { staticClass: "main-bg main-bg-game", style: _vm.mainBgImage }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("navigation", {
          attrs: {
            title: _vm.currentGameName,
            "has-search-bar": true,
            "search-type": "game",
            "search-code": _vm.gameCodeOnUrl,
            "is-blank": true,
            "is-game-page": true,
          },
        }),
        _vm._v(" "),
        _c("section", { staticClass: "game-section" }, [
          _c(
            "ul",
            { staticClass: "cproblem__category" },
            _vm._l(_vm.baseCategories, function (baseCategory) {
              return _c("div", { key: baseCategory.name }, [
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "cproblem__category-link",
                        attrs: {
                          to: {
                            name: "MobileArticleList",
                            params: {
                              gameCode: _vm.gameCodeOnUrl,
                              baseCategory: baseCategory.code,
                            },
                          },
                        },
                      },
                      [
                        _c("div", {
                          staticClass: "cproblem__category-icon",
                          class: [
                            "cproblem__category-icon--" + baseCategory.code,
                          ],
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "cproblem__category-txt" }, [
                          _c("h2", [_vm._v(_vm._s(baseCategory.name))]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "cproblem__category-blank" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "cproblem__category-arrow" }, [
                          _c("div", {
                            staticClass: "cproblem__category-arrow--icon",
                          }),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("hr"),
              ])
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _vm.currentGameId
          ? [
              _c("notification", {
                attrs: { pos: "game", "category-id": _vm.currentGameId },
              }),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }