var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-wrap" },
    [
      _vm.errorMsg === "error_no_matched_data"
        ? [
            _c("section", { staticClass: "form-section" }, [
              _c("div", { staticClass: "reportlist__detail-main" }, [
                _c("div", { staticClass: "reportlist__detail-title" }, [
                  _c("div", { staticClass: "main-inner__not-found" }, [
                    _c("div", { staticClass: "main-inner__not-found-title" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("REPORT__DETAIL_NOT_FOUND")) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "main-inner__not-found-desc",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("REPORT__DETAIL_NOT_FOUND_DESC")
                        ),
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]
        : _vm.isDataReady
        ? [
            _vm.ticket
              ? _c(
                  "section",
                  { staticClass: "form-section" },
                  [
                    _c("div", { staticClass: "reportlist__detail-main" }, [
                      _c("div", { staticClass: "reportlist__detail-title" }, [
                        _c("div", { staticClass: "onlineservice-title" }, [
                          _c("div", { staticClass: "reportlist__detail-top" }, [
                            _c(
                              "div",
                              { staticClass: "reportlist__main-top--title" },
                              [_vm._v(_vm._s(_vm.$t("REPORT__DETAIL_TITLE")))]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "reportlist__detail-top" }, [
                            _c(
                              "div",
                              { staticClass: "reportlist__main-top--remind" },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("REPORT__DETAIL_CS_NUMBER")) +
                                    " #" +
                                    _vm._s(_vm.ticket.csNumber)
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "reportlist__main-top--status" },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t(
                                          "REPORT__STATUS_".concat(
                                            _vm.constants
                                              .TICKET__TICKET_STATUS_MAPPING[
                                              _vm.ticket.ticketStatus
                                            ].TICKET_STATUS
                                          )
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "reportlist__detail-category" },
                            [
                              _c(
                                "div",
                                { staticClass: "reportlist__main-top--title2" },
                                [
                                  !_vm.isLegacy
                                    ? _c(
                                        "dl",
                                        {
                                          staticClass:
                                            "reportlist__main-top--title2-item",
                                        },
                                        [
                                          _c("dt", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("REPORT__DETAIL_GAME")
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("dd", [
                                            _vm._v(_vm._s(_vm.ticket.gameName)),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "dl",
                                    {
                                      staticClass:
                                        "reportlist__main-top--title2-item",
                                    },
                                    [
                                      _c("dt", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "REPORT__DETAIL_BASE_CATEGORY"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _vm._v(
                                          _vm._s(_vm.ticket.baseCategoryName)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "dl",
                                    {
                                      staticClass:
                                        "reportlist__main-top--title2-item",
                                    },
                                    [
                                      _c("dt", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "REPORT__DETAIL_TOPIC_CATEGORY"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.ticket.ticketTopicCategoryName
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "dl",
                                    {
                                      staticClass:
                                        "reportlist__main-top--title2-item",
                                    },
                                    [
                                      _c("dt", [
                                        _vm._v(
                                          _vm._s(_vm.$t("REPORT__DETAIL_TOPIC"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", [
                                        _vm._v(
                                          _vm._s(_vm.ticket.ticketTopicName)
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "reportlist__detail" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "onlineservicedet-accordion",
                            class: {
                              "onlineservicedet-accordion__hide":
                                _vm.isShowingAllReplies,
                            },
                            on: { click: _vm.showAllReplies },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("M_REPORT__REPLY_VIEW_MORE")) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.ticket.replies, function (reply) {
                          return _c("reply", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.shownTicketReplyIds.includes(reply.id) ||
                                  _vm.isShowingAllReplies,
                                expression:
                                  "shownTicketReplyIds.includes(reply.id) || isShowingAllReplies",
                              },
                            ],
                            key: reply.id,
                            attrs: {
                              "cs-number": _vm.ticket.csNumber,
                              "reply-id": reply.id,
                              type: reply.type,
                              "creator-type": reply.creatorType,
                              "created-at": reply.createdAt,
                              content: reply.content,
                              attachments: reply.attachments,
                              numOfAttachments: reply.numOfAttachments,
                              "staff-name": reply.staffName,
                              "is-helpful": reply.isHelpful,
                              "has-rated": reply.isHelpful !== null,
                              "hide-rated": _vm.isLegacy,
                            },
                          })
                        }),
                        _vm._v(" "),
                        _vm.showFeedback
                          ? [
                              _c("feedback", {
                                attrs: {
                                  "cs-number": _vm.ticket.csNumber,
                                  feedback: _vm.ticket.feedback,
                                  "can-provide-feedback":
                                    _vm.ticket.canProvideFeedback,
                                },
                              }),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.canContinueReport
                      ? [
                          _c("continue", {
                            attrs: {
                              "cs-number": _vm.ticket.csNumber,
                              "is-email-required":
                                _vm.ticket.needNotificationEmail,
                              "user-email": _vm.ticket.notificationEmailAddress,
                            },
                            on: {
                              submit: function ($event) {
                                return _vm.getTicketByCsNumber(
                                  _vm.csNumber,
                                  _vm.isLegacy
                                )
                              },
                            },
                          }),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
          ]
        : [
            _c("div", { staticClass: "loading" }, [
              _c("img", {
                staticClass: "icon-loading",
                attrs: {
                  src: `${_vm.CDN}/assets/pc/img/common/loading.gif`,
                  alt: "",
                },
              }),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }