var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "radio-wrap" }, [
    _c("label", { staticClass: "radio" }, [
      _c("input", {
        staticClass: "radio__widget",
        attrs: { type: "radio", name: _vm.name, disabled: _vm.isDisabled },
        domProps: { value: _vm.radioValue, checked: _vm.val },
        on: {
          change: function ($event) {
            _vm.$emit("input", parseInt($event.target.value))
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "radio__indicator" }),
      _vm._v(" "),
      _c("div", { staticClass: "radio__label" }, [
        _vm._v("\n      " + _vm._s(_vm.label) + "\n    "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }