var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-wrap" }, [
    _c("div", { staticClass: "main-bg main-bg-game", style: _vm.mainBgImage }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("navigation", {
          attrs: {
            title: _vm.currentGameName,
            "has-search-bar": true,
            "search-type": "game",
            "search-code": _vm.gameCodeOnUrl,
            "is-blank": true,
            "is-game-page": true,
          },
        }),
        _vm._v(" "),
        _vm.isDataReady
          ? [
              _c("section", { staticClass: "game-section" }, [
                _c(
                  "div",
                  { staticClass: "cproblem__main" },
                  [
                    _c("div", { staticClass: "cproblem__main-top" }, [
                      _c("h2", { staticClass: "cproblem__main-top--title" }, [
                        _vm._v(
                          _vm._s(_vm.currentGameName) +
                            " - " +
                            _vm._s(_vm.currentBaseCategoryName)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.articles.length > 0
                      ? [
                          _c(
                            "ul",
                            { staticClass: "cproblem__main-list" },
                            [
                              _vm._l(_vm.articles, function (article, idx) {
                                return _c(
                                  "li",
                                  {
                                    key: article.agcId,
                                    staticClass: "cproblem__item",
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "cproblem__main-item",
                                        class: {
                                          "cproblem__item-hide":
                                            idx >=
                                              _vm.constants
                                                .GENERAL__FAQ_PER_PAGE &&
                                            !_vm.isShowingAllArticles,
                                        },
                                        attrs: {
                                          to: {
                                            name: "MobileArticle",
                                            params: {
                                              gameCode: _vm.gameCodeOnUrl,
                                              baseCategory:
                                                _vm.baseCategoryNameOnUrl,
                                              agcId: article.agcId,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _c("div", {
                                          staticClass:
                                            "cproblem__main-item--icon",
                                        }),
                                        _vm._v(" "),
                                        _c("p", [
                                          _vm._v(_vm._s(article.title)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }),
                              _vm._v(" "),
                              _vm.articles.length >
                                _vm.constants.GENERAL__FAQ_PER_PAGE &&
                              !_vm.isShowingAllArticles
                                ? [
                                    _c(
                                      "li",
                                      {
                                        staticClass: "cproblem__more",
                                        on: { click: _vm.showAllArticles },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "cproblem__more-title",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t("ARTICLE__SHOW_MORE")
                                                ) +
                                                "\n                    "
                                            ),
                                            _c("div", {
                                              staticClass:
                                                "cproblem__more-arrow",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "cproblem__main-grey" }),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "cproblem__main-entry" },
                      [
                        _vm.showAIHelp
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "cproblem__main-entry--item rectangle-button",
                                on: {
                                  click: function ($event) {
                                    _vm.triggerAIChatting(true)
                                    _vm.gaTrack(
                                      "click",
                                      "mb",
                                      "main",
                                      _vm.userInfo.uid
                                    )
                                  },
                                },
                              },
                              [
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "cproblem__main-entry--name",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              `ARTICLE__TICKET_TITLE__${_vm.gameCodeOnUrl.toUpperCase()}`
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "cproblem__main-entry--desc",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              `ARTICLE__TICKET_DESC__${_vm.gameCodeOnUrl.toUpperCase()}`
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass:
                                    "cproblem__main-entry--icon cproblem__main-entry--createreport",
                                }),
                              ]
                            )
                          : _c(
                              "router-link",
                              {
                                staticClass:
                                  "cproblem__main-entry--item rectangle-button",
                                attrs: {
                                  to: {
                                    name: "MobileTicket",
                                    params: { gameCode: _vm.gameCodeOnUrl },
                                    query: { bc: _vm.baseCategoryNameOnUrl },
                                  },
                                },
                              },
                              [
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "cproblem__main-entry--name",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("ARTICLE__TICKET_TITLE")
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "cproblem__main-entry--desc",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("ARTICLE__TICKET_DESC")
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass:
                                    "cproblem__main-entry--icon cproblem__main-entry--createreport",
                                }),
                              ]
                            ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]),
            ]
          : [
              _c("div", { staticClass: "loading" }, [
                _c("img", {
                  staticClass: "icon-loading",
                  attrs: {
                    src: `${_vm.CDN}/assets/pc/img/common/loading.gif`,
                    alt: "",
                  },
                }),
              ]),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }