var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-wrap" }, [
    _c("div", { staticClass: "main-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("navigation", {
          attrs: {
            title: _vm.$t("VERIFICATION__RESET_PROFILE_TITLE"),
            "has-search-bar": false,
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "main-inner" }, [
          _c(
            "div",
            { staticClass: "profile" },
            [
              _c("breadcrumb", { attrs: { paths: _vm.paths } }),
              _vm._v(" "),
              _c("div", { staticClass: "main-title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("VERIFICATION__RESET_PROFILE_TITLE")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "profileresult-main" }, [
                _c(
                  "div",
                  { staticClass: "profileresult-ret" },
                  [
                    _vm.status ===
                    _vm.constants.APPLICATION__VERIFICATION_RESULTS.SUCCESS
                      ? [
                          _c("div", {
                            staticClass:
                              "profileresult-ret__icon profileresult-ret__icon--success",
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "profileresult-ret__title" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_SUCCESS_TITLE"
                                  )
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "profileresult-ret__text",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t(
                                  "VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_SUCCESS_TEXT"
                                )
                              ),
                            },
                          }),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.status ===
                    _vm.constants.APPLICATION__VERIFICATION_RESULTS.FAILURE
                      ? [
                          _c("div", {
                            staticClass:
                              "profileresult-ret__icon profileresult-ret__icon--failure",
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "profileresult-ret__title" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_FAILURE_TITLE"
                                  )
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "profileresult-ret__text",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t(
                                  "VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_FAILURE_TEXT"
                                )
                              ),
                            },
                          }),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "profileresult-tip" }, [
                  _c(
                    "div",
                    { staticClass: "main-title profileresult-tip__title" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_NOTICE"
                          )
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("ol", { staticClass: "profileresult-tip__list" }, [
                    _c("li", { staticClass: "profileresult-tip__item" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_NOTICE_1"
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "profileresult-tip__item" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_NOTICE_2"
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }