var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-wrap" }, [
    _c("div", { staticClass: "main-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("navigation", {
          attrs: {
            title: _vm.$t("HOME__VERIFICATION_TITLE"),
            "has-search-bar": false,
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "main-inner" }, [
          _c(
            "div",
            { staticClass: "verification" },
            [
              _c("breadcrumb", { attrs: { paths: _vm.paths } }),
              _vm._v(" "),
              _c("div", { staticClass: "main-title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("HOME__VERIFICATION_TITLE")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "verification-inner" }, [
                _c(
                  "div",
                  { staticClass: "verification__list" },
                  _vm._l(_vm.items, function (item) {
                    return _c(
                      "div",
                      { key: item.icon, staticClass: "verification__card" },
                      [
                        _c("div", {
                          staticClass: "verification__card-icon",
                          class: ["verification__card-icon--" + item.icon],
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "verification__card-title" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(item.title) +
                              "\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "verification__card-content",
                          domProps: { innerHTML: _vm._s(item.content) },
                        }),
                        _vm._v(" "),
                        item.isExternalLink
                          ? [
                              _c(
                                "a",
                                {
                                  staticClass: "verification__card-verf",
                                  attrs: { href: item.link, target: "_blank" },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(item.linkText) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          : [
                              _c(
                                "router-link",
                                {
                                  staticClass: "verification__card-verf",
                                  attrs: { to: { name: item.routerName } },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(item.linkText) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ],
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }