import _ from 'lodash';

function getParam(paramName, url) {
  const href = url || window.location.href;
  const name = paramName.replace(/[[]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(href);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function camelizeStr(str) {
  return str.replace(/[_.-](\w|$)/g, (__, x) => x.toUpperCase());
}

function snakifyStr(str) {
  return str.replace(/(?:^|\.?)([A-Z])/g, (__, x) => `_${x.toLowerCase()}`);
}

function convertCase(convertFunc) {
  function converter(thing) {
    if (thing instanceof Array) {
      return thing.map((i) => converter(i));
    }
    if (thing instanceof Object) {
      const newObj = {};
      Object.keys(thing).forEach((k) => {
        newObj[convertFunc(k)] = converter(thing[k]);
      });
      return newObj;
    }
    return thing;
  }
  return converter;
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function makeMultiSelectOptions(options, name = 'All') {
  return {
    name,
    options,
  };
}

function addValueField(obj, fromField) {
  return {
    value: obj[fromField],
    ...obj,
  };
}

function addValueFieldFromId(obj) {
  return addValueField(obj, 'id');
}

function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

function isEmptyArray(arr) {
  return arr.length === 0;
}

function isEmpty(val) {
  switch (typeof val) {
    case 'undefined':
      return true;

    case 'string':
      return val.length === 0;

    case 'object':
      if (Array.isArray(val)) {
        return val.length === 0;
      }
      return _.isEmpty(val);

    default:
      return !val;
  }
}

function isNotEmpty(nullOrArr) {
  return !isEmpty(nullOrArr);
}

function makeObjFromArr(arr) {
  return !isEmpty(arr)
    ? arr.reduce((res, item) => {
        // eslint-disable-next-line no-param-reassign
        res[item.id] = item;
        return res;
      }, {})
    : {};
}

function getNameFromObj(obj, val, def) {
  return obj && obj[val] ? obj[val].name : def;
}

function joinArray(arr) {
  return isEmpty(arr) ? '' : arr.join(',');
}

function removeEmpty(obj) {
  // eslint-disable-next-line no-param-reassign
  Object.keys(obj).forEach((key) => isEmpty(obj[key]) && delete obj[key]);
  return obj;
}

function parseIntOrNull(num) {
  return Number.isNaN(parseInt(num, 10)) ? null : parseInt(num, 10);
}

function getOrNull(obj, field) {
  return (obj || {})[field] || null;
}

function expandIdToObj(arr, obj) {
  return arr.map((id) => obj[id] || {}).filter((e) => e);
}

function parseQuery(search) {
  if (!search || !search.length) {
    return {};
  }

  const ret = search.slice(0, 1) === '?' ? search.slice(1) : search;

  return ret.split('&').reduce((acc, hash) => {
    const [key, val] = hash.split('=');
    acc[decodeURIComponent(key)] = decodeURIComponent(val);
    return acc;
  }, {});
}

function hasOwn(obj, key) {
  return Object.prototype.hasOwnProperty.call(obj, key);
}

export default {
  getParam,
  camelizeStr,
  snakifyStr,
  delay,
  makeMultiSelectOptions,
  addValueField,
  addValueFieldFromId,
  isEmptyObject,
  isEmptyArray,
  isEmpty,
  isNotEmpty,
  makeObjFromArr,
  getNameFromObj,
  joinArray,
  removeEmpty,
  parseIntOrNull,
  getOrNull,
  expandIdToObj,
  camelizeKeys: convertCase(camelizeStr),
  snakifyKeys: convertCase(snakifyStr),
  parseQuery,
  hasOwn,
};
