var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-wrap main-wrap--hassearch main-wrap--game" },
    [
      _c("div", { staticClass: "main-bg", style: _vm.mainBgImage }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c("navigation", {
            attrs: {
              title: _vm.currentGameName,
              "has-search-bar": true,
              "search-type": "game",
              "search-code": _vm.gameCodeOnUrl,
              "is-game-page": true,
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "main-inner main-inner--nobg" }, [
            _c(
              "div",
              { staticClass: "faqdetail" },
              [
                _c("breadcrumb", {
                  attrs: { paths: _vm.paths, "has-border": true },
                }),
                _vm._v(" "),
                _vm.article
                  ? [
                      _c(
                        "div",
                        { staticClass: "faqdetail-main" },
                        [
                          _c("div", { staticClass: "faqdetail__title" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.article.title) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "faqdetail__content" }, [
                            _c("div", {
                              staticClass: "ip-solution__txt",
                              domProps: {
                                innerHTML: _vm._s(_vm.article.content),
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          !_vm.utils.isEmptyArray(_vm.article.tags)
                            ? [
                                _c("div", { staticClass: "faqdetail__aside" }, [
                                  _c(
                                    "div",
                                    { staticClass: "faqdetail__aside-item" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("ARTICLE__TAGS")) +
                                          "\n                  "
                                      ),
                                      _vm._l(
                                        _vm.article.tags,
                                        function (tag, idx) {
                                          return _c(
                                            "a",
                                            {
                                              key: idx,
                                              staticClass:
                                                "faqdetail__aside-tag",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(tag) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.utils.isEmptyArray(_vm.article.relatedArticles)
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "faqdetail-related" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "faqdetail-related__title",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t("ARTICLE__RELATED_ARTICLE")
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "faqdetail-related__list",
                                      },
                                      _vm._l(
                                        _vm.article.relatedArticles,
                                        function (relatedArticle) {
                                          return _c(
                                            "li",
                                            {
                                              key: relatedArticle.id,
                                              staticClass:
                                                "faqdetail-related__item",
                                            },
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass:
                                                    "faqdetail-related__link",
                                                  attrs: {
                                                    to: {
                                                      name: "ArticleGeneral",
                                                      params: {
                                                        id: relatedArticle.id,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "faqdetail-related__link-title",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            relatedArticle.title
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  : [
                      _c("div", { staticClass: "loading" }, [
                        _c("img", {
                          staticClass: "icon-loading",
                          attrs: {
                            src: `${_vm.CDN}/assets/pc/img/common/loading.gif`,
                            alt: "",
                          },
                        }),
                      ]),
                    ],
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "cproblem-entry" },
              [
                _vm.showAIHelp
                  ? _c(
                      "a",
                      {
                        staticClass: "cproblem-entry__item rectangle-button",
                        on: {
                          click: function ($event) {
                            return _vm.triggerAIChatting(true)
                          },
                        },
                      },
                      [
                        _c("div", {
                          staticClass:
                            "cproblem-entry__item-icon cproblem-entry__item-icon--createreport",
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "cproblem-entry__item-content" },
                          [
                            _c(
                              "div",
                              { staticClass: "cproblem-entry__item-desc" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        `ARTICLE__TICKET_SUBTITLE__${_vm.gameCodeOnUrl.toUpperCase()}`
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "cproblem-entry__item-name" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        `ARTICLE__TICKET_TITLE__${_vm.gameCodeOnUrl.toUpperCase()}`
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "cproblem-entry__item-desc" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        `ARTICLE__TICKET_DESC__${_vm.gameCodeOnUrl.toUpperCase()}`
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _c(
                      "router-link",
                      {
                        staticClass: "cproblem-entry__item rectangle-button",
                        attrs: {
                          to: {
                            name: "Ticket",
                            params: { gameCode: _vm.gameCodeOnUrl },
                            query: { bc: _vm.baseCategoryNameOnUrl },
                          },
                        },
                      },
                      [
                        _c("div", {
                          staticClass:
                            "cproblem-entry__item-icon cproblem-entry__item-icon--createreport",
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "cproblem-entry__item-content" },
                          [
                            _c(
                              "div",
                              { staticClass: "cproblem-entry__item-desc" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("ARTICLE__TICKET_SUBTITLE")) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "cproblem-entry__item-name" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("ARTICLE__TICKET_TITLE")) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "cproblem-entry__item-desc" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("ARTICLE__TICKET_DESC")) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }