var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "main-wrap reportlist__container",
      class: { "bg-white": _vm.isTicketListEmpty },
      on: { scroll: _vm.onScroll },
    },
    [
      _c(
        "section",
        { staticClass: "game-section" },
        [
          _vm.tickets && !_vm.utils.isEmptyArray(_vm.tickets)
            ? [
                _vm._l(_vm.tickets, function (ticket) {
                  return _c(
                    "div",
                    { key: ticket.csNumber, staticClass: "reportlist__main" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "searchlist__cards",
                          attrs: {
                            to: {
                              name: "MobileReport",
                              params: {
                                csNumber: ticket.csNumber,
                                isLegacy: _vm.isLegacy,
                              },
                              query: _vm.isLegacy
                                ? {
                                    source:
                                      _vm.constants
                                        .TICKET__TICKET_STATUS_FOR_USER.LEGACY,
                                  }
                                : undefined,
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "reportlist__main-top" }, [
                            _c(
                              "div",
                              { staticClass: "reportlist__main-top--title" },
                              [
                                _c("div", {
                                  staticClass: "reportlist__main-title--icon",
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(ticket.csNumber) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            ticket.hasUnreadReply
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "reportlist__main-top--status",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "REPORT__STATUS_".concat(
                                              _vm.constants
                                                .TICKET__TICKET_STATUS_MAPPING[
                                                ticket.ticketStatus
                                              ].TICKET_STATUS
                                            )
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "reportlist__main-top--remind--view reportlist__main-top--remind--view--unread",
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t("REPORT__VIEW_UNREAD")
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "reportlist__main-top--status",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "REPORT__STATUS_".concat(
                                              _vm.constants
                                                .TICKET__TICKET_STATUS_MAPPING[
                                                ticket.ticketStatus
                                              ].TICKET_STATUS
                                            )
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "reportlist__main-top--remind--view",
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t("REPORT__VIEW_READ")
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "reportlist__content" }, [
                            _c(
                              "div",
                              { staticClass: "reportlist__detail-category" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "reportlist__main-top--title2",
                                  },
                                  [
                                    !_vm.isLegacy
                                      ? _c(
                                          "dl",
                                          {
                                            staticClass:
                                              "reportlist__main-top--title2-item",
                                          },
                                          [
                                            _c("dt", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("REPORT__DETAIL_GAME")
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("dd", [
                                              _vm._v(_vm._s(ticket.gameName)),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "dl",
                                      {
                                        staticClass:
                                          "reportlist__main-top--title2-item",
                                      },
                                      [
                                        _c("dt", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "REPORT__DETAIL_BASE_CATEGORY"
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("dd", [
                                          _vm._v(
                                            _vm._s(ticket.baseCategoryName)
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "dl",
                                      {
                                        staticClass:
                                          "reportlist__main-top--title2-item",
                                      },
                                      [
                                        _c("dt", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "REPORT__DETAIL_TOPIC_CATEGORY"
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("dd", [
                                          _vm._v(
                                            _vm._s(
                                              ticket.ticketTopicCategoryName
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "dl",
                                      {
                                        staticClass:
                                          "reportlist__main-top--title2-item",
                                      },
                                      [
                                        _c("dt", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("REPORT__DETAIL_TOPIC")
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("dd", [
                                          _vm._v(
                                            _vm._s(ticket.ticketTopicName)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "reportlist__question-3" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.utils.formatTimestampToString(
                                        ticket.createdAt,
                                        _vm.$t("GENERAL__DATETIME_FORMAT")
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _vm.isLoadingMore
                  ? _c("div", { staticClass: "reportlist__nomore" }, [
                      _c("img", {
                        staticClass: "icon-loading",
                        attrs: {
                          src: `${_vm.CDN}/assets/pc/img/common/loading.gif`,
                          alt: "",
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.canLoadMore
                  ? _c("div", { staticClass: "reportlist__nomore" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("M_REPORT__NO_MORE_RECORD")) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
              ]
            : _vm.isTicketListEmpty
            ? [
                _c("div", { staticClass: "reportlist-main" }, [
                  _c("div", { staticClass: "faqdetail-title" }, [
                    _c("div", { staticClass: "faqdetail__question" }, [
                      _vm._v(_vm._s(_vm.$t("REPORT__TITLE"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "reportlist-inner" }, [
                    _c("div", {
                      staticClass:
                        "reportlist__inner-icon reportlist__inner-icon--2",
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "reportlist__inner-txt" }, [
                      _vm._v(_vm._s(_vm.$t("REPORT__NO_RECORD"))),
                    ]),
                  ]),
                ]),
              ]
            : [
                _c("div", { staticClass: "reportlist__nomore" }, [
                  _c("img", {
                    staticClass: "icon-loading",
                    attrs: {
                      src: `${_vm.CDN}/assets/pc/img/common/loading.gif`,
                      alt: "",
                    },
                  }),
                ]),
              ],
          _vm._v(" "),
          this.isTwVersion || this.isSmpVersion
            ? _c(
                "div",
                {
                  staticClass: "legacy-btn",
                  on: { click: _vm.toggleLegacyStatus },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.isLegacy
                          ? _vm.$t("M_REPORT__BACK_TO_RECENT")
                          : _vm.$t("M_REPORT__BACK_TO_LEGACY")
                      ) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }