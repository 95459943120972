var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasLoginPopup
    ? _c("div", { staticClass: "notification-wrap" }, [
        _c("div", {
          staticClass: "notification-dimmer",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.switchLoginPopup(false)
            },
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "notification__login" }, [
          _c("div", { staticClass: "notification-inner" }, [
            _c("div", { staticClass: "notification__title" }, [
              _c("div", { staticClass: "notification__title-txt" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("GENERAL__LOGIN")) +
                    "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "notification__platforms" },
              _vm._l(_vm.platformIds, function (platformId) {
                return _c(
                  "div",
                  {
                    key: platformId,
                    staticClass: "notification__content-icon",
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: `${_vm.CDN}/assets/share/img/icon-circle-platform-${platformId}.png`,
                        alt: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toLogin(platformId)
                        },
                      },
                    }),
                    _vm._v(" "),
                    platformId === _vm.constants.GENERAL__GOP_PLATFORM.GARENA
                      ? _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.switchLoginPopup(false)
                              },
                            },
                          },
                          [
                            _c(
                              "router-link",
                              { attrs: { to: { name: "CantLogin" } } },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("POPUP__CANT_LOGIN")) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("a", {
              staticClass: "notification__close",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.switchLoginPopup(false)
                },
              },
            }),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }