var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-wrap" },
    [
      _c("div", { staticClass: "main-bg" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c("navigation", {
            attrs: {
              title: _vm.$t("GENERAL__TITLE"),
              "has-search-bar": true,
              "is-blank": true,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "main-inner main-inner--nobg" },
            [
              _vm.isTwVersion
                ? [
                    _c("div", { staticClass: "home" }, [
                      _c(
                        "div",
                        { staticClass: "home-main" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "home-entry",
                              attrs: { to: { name: "MobileCantLogin" } },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "home-entry__item-content" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "home-entry__item-icon" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: `${_vm.CDN}/assets/mobile/img/home/icon-cantlogin@2.svg`,
                                          alt: "",
                                          height: "40",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "home-entry__item-name" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("M_HOME__CANT_LOGIN_TITLE")
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "home-main" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "home-entry",
                              attrs: { to: { name: "MobileVerification" } },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "home-entry__item-content" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "home-entry__item-icon" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: `${_vm.CDN}/assets/mobile/img/home/icon-verification@2.svg`,
                                          alt: "",
                                          height: "40",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "home-entry__item-name" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("M_HOME__VERIFICATION_TITLE")
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "home" },
                [
                  _vm.isJpVersion
                    ? [
                        _c(
                          "div",
                          { staticClass: "home-main" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "home-entry",
                                attrs: {
                                  to: {
                                    name: "MobileBaseCategoryList",
                                    params: { gameCode: "ff" },
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "home-entry__item-content" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "home-entry__item-icon" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: `${_vm.CDN}/assets/mobile/img/home/icon-games@2.svg`,
                                            alt: "",
                                            height: "40",
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "home-entry__item-name" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("M_HOME__REPORT__TITLE")
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "div",
                          { staticClass: "home-main" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "home-entry",
                                attrs: { to: { name: "MobileGameList" } },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "home-entry__item-content" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "home-entry__item-icon" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: `${_vm.CDN}/assets/mobile/img/home/icon-games@2.svg`,
                                            alt: "",
                                            height: "40",
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "home-entry__item-name" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("M_HOME__REPORT__TITLE")
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                  _vm._v(" "),
                  _vm.isTwVersion
                    ? [
                        _c("div", { staticClass: "home-main" }, [
                          _c(
                            "a",
                            {
                              staticClass: "home-entry",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.popupChooseRecord(true)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "home-entry__item-content" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "home-entry__item-icon" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: `${_vm.CDN}/assets/mobile/img/home/icon-list@2.svg`,
                                          alt: "",
                                          height: "40",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "home-entry__item-name" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("M_HOME__SERVICE_RECORD_TITLE")
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    : [
                        _c(
                          "div",
                          { staticClass: "home-main" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "home-entry",
                                attrs: { to: { name: "MobileReportList" } },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "home-entry__item-content" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "home-entry__item-icon" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: `${_vm.CDN}/assets/mobile/img/home/icon-list@2.svg`,
                                            alt: "",
                                            height: "40",
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "home-entry__item-name" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "M_HOME__SERVICE_RECORD_TITLE"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                ],
                2
              ),
              _vm._v(" "),
              _vm.isTwVersion
                ? [
                    _c("div", { staticClass: "home" }, [
                      _c(
                        "div",
                        { staticClass: "home-main" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "home-entry",
                              attrs: { to: { name: "MobileNewsList" } },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "home-entry__item-content" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "home-entry__item-icon" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: `${_vm.CDN}/assets/mobile/img/home/icon-news@2.svg`,
                                          alt: "",
                                          height: "40",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "home-entry__item-name" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("M_HOME__NEWS_TITLE"))
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "home-main home-main-none" }),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("notification", { attrs: { pos: "home" } }),
      _vm._v(" "),
      _vm.isShowingPopupChooseRecord
        ? _c("popup-choose-record", {
            on: {
              close: function ($event) {
                return _vm.popupChooseRecord(false)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }