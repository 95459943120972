var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-wrap main-wrap--hassearch" }, [
    _c("div", { staticClass: "main-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("navigation", {
          attrs: { title: _vm.$t("GENERAL__TITLE"), "has-search-bar": true },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "main-inner main-inner--nobg" }, [
          _c(
            "div",
            { staticClass: "faqdetail" },
            [
              _c("breadcrumb", {
                attrs: { paths: _vm.paths, "has-border": true },
              }),
              _vm._v(" "),
              _vm.article
                ? [
                    _c(
                      "div",
                      { staticClass: "faqdetail-main" },
                      [
                        _c("div", { staticClass: "faqdetail__title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.article.title) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "faqdetail__content" }, [
                          _c("div", {
                            staticClass: "ip-solution__txt",
                            domProps: {
                              innerHTML: _vm._s(_vm.article.content),
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        !_vm.utils.isEmptyArray(_vm.article.tags)
                          ? [
                              _c("div", { staticClass: "faqdetail__aside" }, [
                                _c(
                                  "div",
                                  { staticClass: "faqdetail__aside-item" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.$t("ARTICLE__TAGS")) +
                                        "\n                  "
                                    ),
                                    _vm._l(
                                      _vm.article.tags,
                                      function (tag, idx) {
                                        return _c(
                                          "a",
                                          {
                                            key: idx,
                                            staticClass: "faqdetail__aside-tag",
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(tag) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.utils.isEmptyArray(_vm.article.relatedArticles)
                          ? [
                              _c("div", { staticClass: "faqdetail-related" }, [
                                _c(
                                  "div",
                                  { staticClass: "faqdetail-related__title" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t("ARTICLE__RELATED_ARTICLE")
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  { staticClass: "faqdetail-related__list" },
                                  _vm._l(
                                    _vm.article.relatedArticles,
                                    function (related_article) {
                                      return _c(
                                        "li",
                                        {
                                          key: related_article.id,
                                          staticClass:
                                            "faqdetail-related__item",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "faqdetail-related__link",
                                              attrs: {
                                                to: {
                                                  name: "ArticleGeneral",
                                                  params: {
                                                    id: _vm.relatedArticle.id,
                                                  },
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "faqdetail-related__link-title",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        related_article.title
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                : [
                    _c("div", { staticClass: "loading" }, [
                      _c("img", {
                        staticClass: "icon-loading",
                        attrs: {
                          src: `${_vm.CDN}/assets/pc/img/common/loading.gif`,
                          alt: "",
                        },
                      }),
                    ]),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "cproblem-entry" }, [
            _c(
              "a",
              {
                staticClass: "cproblem-entry__item rectangle-button",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.openGameList.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", {
                  staticClass:
                    "cproblem-entry__item-icon cproblem-entry__item-icon--createreport",
                }),
                _vm._v(" "),
                _c("div", { staticClass: "cproblem-entry__item-content" }, [
                  _c("div", { staticClass: "cproblem-entry__item-desc" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("ARTICLE__TICKET_SUBTITLE")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cproblem-entry__item-name" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("ARTICLE__TICKET_TITLE")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cproblem-entry__item-desc" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("ARTICLE__TICKET_DESC")) +
                        "\n            "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _vm.isShowingGames && _vm.games
      ? _c("div", { staticClass: "notification-wrap" }, [
          _c("div", { staticClass: "notification-dimmer" }),
          _vm._v(" "),
          _c("div", { staticClass: "notification" }, [
            _c("div", { staticClass: "notification-inner" }, [
              _c("div", { staticClass: "notification__title" }, [
                _c("div", { staticClass: "notification__title-icon" }),
                _vm._v(" "),
                _c("div", { staticClass: "notification__title-txt" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("ARTICLE__TICKET_CHOOSE_GAME")) +
                      "\n          "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "notification__main" }, [
                _c("div", { staticClass: "notification__content" }, [
                  _c("div", { staticClass: "notification__content-txt" }, [
                    _c("div", { staticClass: "home-games" }, [
                      _c("div", { staticClass: "home-games__inner" }, [
                        _c("div", { staticClass: "home-section__title" }, [
                          _c("div", {
                            staticClass:
                              "home-section__title-icon home-section__title-icon--pcgames",
                          }),
                          _vm._v(" "),
                          _c("h3", { staticClass: "home-section__title-txt" }, [
                            _vm._v(
                              "\n                      " +
                                _vm._s(_vm.$t("HOME__PC_GAME_QUESTION")) +
                                "\n                    "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "home-games article_general__game" },
                          _vm._l(_vm.games.pc, function (game) {
                            return _c(
                              "li",
                              { key: game.id, staticClass: "home-games-game" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "cproblem__link",
                                    attrs: {
                                      to: {
                                        name: "Ticket",
                                        params: { gameCode: game.code },
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(game.name) +
                                        "\n                      "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "home-section__title" }, [
                          _c("div", {
                            staticClass:
                              "home-section__title-icon home-section__title-icon--mgames",
                          }),
                          _vm._v(" "),
                          _c("h3", { staticClass: "home-section__title-txt" }, [
                            _vm._v(
                              "\n                      " +
                                _vm._s(_vm.$t("HOME__MOBILE_GAME_QUESTION")) +
                                "\n                    "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "home-games article_general__game" },
                          _vm._l(_vm.games.mobile, function (game) {
                            return _c(
                              "li",
                              { key: game.id, staticClass: "home-games-game" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "cproblem__link",
                                    attrs: {
                                      to: {
                                        name: "Ticket",
                                        params: { gameCode: game.code },
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(game.name) +
                                        "\n                      "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("a", {
                staticClass: "notification__close",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.closeGameList.apply(null, arguments)
                  },
                },
              }),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }