var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-wrap" }, [
    _c("div", { staticClass: "main-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("navigation", {
          attrs: {
            title: _vm.$t("GENERAL__TITLE"),
            "has-search-bar": true,
            "is-blank": true,
          },
        }),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "game-section" },
          [
            _c("div", { staticClass: "searchlist__main-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("SEARCH__RESULT_TITLE")) +
                  "\n        "
              ),
              _c("span", { staticClass: "search__keyword" }, [
                _vm._v(_vm._s(_vm.searchString)),
              ]),
            ]),
            _vm._v(" "),
            _vm.searchResults && _vm.searchResults.length > 0
              ? _vm._l(_vm.searchResults, function (result) {
                  return _c(
                    "div",
                    { key: result.id, staticClass: "searchlist__main" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "searchlist__cards",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.toArticle(result.id)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "searchlist__main-top" }, [
                            _vm._v("Q：" + _vm._s(result.title)),
                          ]),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "searchlist__recmd",
                            domProps: { innerHTML: _vm._s(result.content) },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "searchlist__main-more" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("SEARCH__READ_MORE")) +
                                " \n              "
                            ),
                            _c(
                              "span",
                              { staticClass: "searchlist__main-more--arrow" },
                              [_vm._v(">")]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  )
                })
              : _vm.searchResults
              ? [
                  _c("div", { staticClass: "searchlist__main-title" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("SEARCH__NO_RESULT")) +
                        "\n        "
                    ),
                  ]),
                ]
              : [
                  _c("div", { staticClass: "loading" }, [
                    _c("img", {
                      staticClass: "icon-loading",
                      attrs: {
                        src: `${_vm.CDN}/assets/pc/img/common/loading.gif`,
                        alt: "",
                      },
                    }),
                  ]),
                ],
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }