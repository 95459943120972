var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-wrap" }, [
    _c("section", { staticClass: "game-section" }, [
      _c("div", { staticClass: "reportlist-main" }, [
        _c("div", { staticClass: "faqdetail-title" }, [
          _c("div", { staticClass: "faqdetail__question" }, [
            _vm._v(_vm._s(_vm.$t("VERIFICATION__RESET_PROFILE_TITLE"))),
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "reportlist-result-subhead",
            domProps: {
              innerHTML: _vm._s(
                _vm.$t(
                  "M_VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_SUCCESS_TEXT"
                )
              ),
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "reportlist-inner" },
          [
            _vm.status ===
            _vm.constants.APPLICATION__VERIFICATION_RESULTS.SUCCESS
              ? [
                  _c("div", {
                    staticClass:
                      "reportlist__inner-icon reportlist__inner-icon--1",
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "reportlist__inner-txt" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t(
                            "VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_SUCCESS_TITLE"
                          )
                        ) +
                        "\n          "
                    ),
                  ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.status ===
            _vm.constants.APPLICATION__VERIFICATION_RESULTS.FAILURE
              ? [
                  _c("div", {
                    staticClass:
                      "reportlist__inner-icon reportlist__inner-icon--2",
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "reportlist__inner-txt" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t(
                            "VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_FAILURE_TITLE"
                          )
                        ) +
                        "\n          "
                    ),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "reportlist__return" },
      [
        _c(
          "router-link",
          {
            staticClass: "reportlist__inner-verf",
            attrs: { to: { name: "Home" } },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t(
                    "M_VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_BUTTON"
                  )
                ) +
                "\n    "
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }