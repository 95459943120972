<template>
  <div class="main-wrap main-wrap--game">
    <div class="main-bg" :style="mainBgImage" />
    <div class="main">
      <navigation :title="$t('TICKET__TITLE')" :is-game-page="true" />
      <div class="main-inner">
        <div class="report">
          <breadcrumb :paths="paths" />
          <div class="main-title">
            {{ $t('TICKET__TITLE') }}
          </div>
          <div class="report-main">
            <dl class="report-warning">
              <dt class="report-warning__title">{{ $t('TICKET__NOTICE') }}</dt>
              <dd>
                <ol class="report-warning__list">
                  <li class="report-warning__item" v-html="$t('TICKET__NOTICE_1')"></li>
                  <li class="report-warning__item" v-html="$t('TICKET__NOTICE_2')"></li>
                  <li class="report-warning__item" v-html="$t('TICKET__NOTICE_3')"></li>
                  <li class="report-warning__item" v-html="$t('TICKET__NOTICE_4')"></li>
                </ol>
              </dd>
            </dl>
            <form class="report-form">
              <p class="report-form__tip">
                {{ $t('TICKET__REMINDER') }}
              </p>

              <template v-if="isTicketTopicCategoryDataReady">
                <!-- FIRST STEP -->
                <template v-if="!hasChosenTicketTopicCategory">
                  <ol class="report-form__ol">
                    <li class="report-form__row">
                      <div class="report-form__label">
                        {{ $t('TICKET__QUESTION_1') }}
                      </div>
                      <div class="report-form__radios">
                        <custom-radio
                          v-for="category in baseCategoriesToDisplay"
                          :key="category.id"
                          :label="category.name"
                          :name="'base_category'"
                          :radio-value="category.id"
                          v-model="userCheckedBaseCategoryId"
                        />
                      </div>
                    </li>
                    <template v-if="userCheckedBaseCategoryId">
                      <li class="report-form__row">
                        <div class="report-form__label">
                          {{ $t('TICKET__QUESTION_2') }}
                        </div>
                        <div class="report-form__radios ttc-radios">
                          <custom-radio
                            v-for="ttc in currentTicketTopicCategories"
                            :key="ttc.id"
                            :label="ttc.name"
                            :name="'ticket_topic_category'"
                            :radio-value="ttc.id"
                            v-model="userCheckedTicketTopicCategoryId"
                          />
                        </div>
                      </li>
                    </template>
                  </ol>
                  <div class="report-form__divider"></div>
                  <div class="report-form__next">
                    <div class="rountbutton">
                      <button class="rountbutton__widget" :disabled="!isFirstButtonEnabled" @click.prevent="lockTicketTopicCategoryId">
                        {{ $t('GENERAL__BUTTON_NEXT_STEP') }}
                        <vue-countdown v-if="isCoolingDown" :time="10000" @end="handleCooldownEnd">
                          <template slot-scope="props">({{ props.totalSeconds }})</template>
                        </vue-countdown>
                      </button>
                    </div>
                  </div>
                </template>
                <!-- FIRST STEP END -->

                <!-- SECOND STEP -->
                <template v-else>
                  <div class="report-form__row">
                    <div class="report-form__inline">
                      <div class="report-form__label">
                        {{ $t('TICKET__FIELD_GAME') }}
                      </div>
                      <div class="report-form__field">
                        <custom-input :value="currentGameName" :isDisabled="true" />
                      </div>
                    </div>
                  </div>
                  <div class="report-form__row">
                    <div class="report-form__inline">
                      <div class="report-form__label">
                        {{ $t('TICKET__FIELD_BASE_CATEGORY') }}
                      </div>
                      <div class="report-form__field">
                        <custom-input :value="currentBaseCategoryName" :isDisabled="true" />
                      </div>
                    </div>
                  </div>
                  <div class="report-form__row">
                    <div class="report-form__inline">
                      <div class="report-form__label">
                        {{ $t('TICKET__FIELD_TICKET_TOPIC_CATEGORY') }}
                      </div>
                      <div class="report-form__field">
                        <custom-input :value="currentTicketTopicCategoryName" :isDisabled="true" />
                      </div>
                    </div>
                  </div>
                  <div class="report-form__row">
                    <div class="report-form__inline">
                      <div class="report-form__label">
                        {{ $t('TICKET__FIELD_TICKET_TOPIC') }}
                      </div>
                      <div class="report-form__field">
                        <custom-select
                          :placeholder="$t('GENERAL__PLACEHOLDER_DEFAULT_SELECT')"
                          :options="ticketTopicIdMapping"
                          v-model="userSelectedTicketTopicId"
                        />
                      </div>
                    </div>
                  </div>

                  <!-- Dependent Questions -->
                  <template v-if="userSelectedTicketTopicId">
                    <div class="report-form__row form__inline" v-if="shouldShowMyinfo">
                      <div class="report-form__inline">
                        <div class="report-form__label"></div>
                        <div class="report-form__field">
                          <div class="report-form__next">
                            <div class="rountbutton">
                              <button type="button" class="rountbutton__widget" @click="getMyinfo">
                                {{ $t('GENERAL__BUTTON_MYINFO') }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <template v-for="field in currentTicketTopicFields">
                      <div class="report-form__row" :key="field.id">
                        <div class="report-form__inline">
                          <div class="report-form__label">
                            {{ field.displayName }}
                            <span v-if="field.isRequired">*</span>
                          </div>
                          <div class="report-form__field">
                            <template v-if="field.type === 'input'">
                              <custom-input
                                :name="field.name"
                                :placeholder="field.attributes.placeholder"
                                :is-required="field.isRequired"
                                :error-msg="$t('GENERAL__INPUT_ERROR_REQUIRED')"
                                :is-valid-if-null="isValidIfNull"
                                :is-limit-length="true"
                                v-model="fieldsValue[field.name]"
                                :readonly="canInjectKeys.indexOf(field.name) >= 0"
                              />
                            </template>
                            <template v-else-if="field.type === 'textarea'">
                              <custom-textarea
                                :name="field.name"
                                :placeholder="field.attributes.placeholder"
                                :is-required="field.isRequired"
                                :error-msg="$t('GENERAL__INPUT_ERROR_REQUIRED')"
                                :is-valid-if-null="isValidIfNull"
                                :is-limit-length="true"
                                v-model="fieldsValue[field.name]"
                              />
                            </template>
                            <template v-if="errors && errors[field.name]">
                              <div class="report-form__error">{{ field.attributes.error }}。</div>
                            </template>
                            <template v-if="field.type === 'file'">
                              <uploader
                                :name="field.name"
                                @uploading="updateUploadingFiles"
                                @uploaded="addUploadedFileIds"
                                @removed="removeUploadedFileIds"
                                :label="$t('GENERAL__BUTTON_UPLOAD_FILE')"
                                :is-required="field.isRequired"
                                :error-msg="$t('GENERAL__INPUT_ERROR_REQUIRED')"
                                :is-valid-if-null="isValidIfNull"
                              />
                            </template>
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>
                  <!-- Dependent Questions END  -->

                  <div class="report-form__divider"></div>
                  <div class="report-form__row">
                    <div class="report-form__inline">
                      <div class="report-form__label"></div>
                      <div class="report-form__field">
                        <custom-checkbox
                          name="ticket_email_checkbox"
                          :label="$t('TICKET__EMAIL_FIELD_CHECKBOX')"
                          v-model="isEmailRequired"
                        />
                      </div>
                    </div>
                  </div>
                  <template v-if="isEmailRequired">
                    <div class="report-form__row">
                      <div class="report-form__inline">
                        <div class="report-form__label">
                          {{ $t('TICKET__EMAIL') }}
                        </div>
                        <div class="report-form__field">
                          <custom-input
                            name="notification_email_address"
                            :placeholder="$t('GENERAL__INPUT_PLACEHOLDER_HALFWIDTH')"
                            :custom-type="constants.GENERAL__INPUT_TYPES.EMAIL"
                            :error-msg="$t('GENERAL__INPUT_ERROR_EMAIL_FORMAT')"
                            v-model="emailAddress"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                  <div class="report-form__row">
                    <div class="report-form__inline">
                      <div class="report-form__label"></div>
                      <div class="report-form__field">
                        <custom-checkbox name="ticket_tos_checkbox" :label="$t('TICKET__TOS_CHECKBOX')" v-model="isTosChecked" />
                        <div class="report-form__error">{{ $t('TICKET__TOS_NOTICE') }}</div>
                        <div class="report-form__next report-form__next2">
                          <div class="rountbutton">
                            <button class="rountbutton__widget" :disabled="!isSecondButtonEnabled" @click.prevent="submitTicket">
                              {{ $t('GENERAL__BUTTON_SUBMIT_TICKET') }}
                              <vue-countdown v-if="isSubmitting" :time="10000" @end="handleCountdownEnd">
                                <template slot-scope="props">({{ props.totalSeconds }})</template>
                              </vue-countdown>
                            </button>
                            <div v-if="isTosChecked && !isSecondButtonEnabled && !isSubmitting" class="report-form__error">
                              {{ $t('TICKET__SUBMIT_NOTICE') }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <!-- SECOND STEP END -->
              </template>
              <template v-else>
                <div class="loading">
                  <img class="icon-loading" :src="`${CDN}/assets/pc/img/common/loading.gif`" alt="" />
                </div>
              </template>
            </form>
          </div>
        </div>
      </div>
    </div>

    <confirm
      v-if="isShowingConfirmLeaving"
      :title="$t('POPUP__ALERT')"
      :content="$t('POPUP__CONFIRM_LEAVE')"
      @close="closeConfirmLeaving"
      @confirm="changePage"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import VueCountdown from '@chenfengyuan/vue-countdown';
import Breadcrumb from '@/components/pc/layouts/Breadcrumb';
import Navigation from '@/components/pc/layouts/Navigation';
import CustomCheckbox from '@/components/pc/layouts/partial/CustomCheckbox';
import CustomInput from '@/components/share/layouts/partial/CustomInput';
import CustomRadio from '@/components/pc/layouts/partial/CustomRadio';
import CustomTextarea from '@/components/share/layouts/partial/CustomTextarea';
import CustomSelect from '@/components/share/layouts/partial/CustomSelect';
import Confirm from '@/components/share/layouts/partial/Confirm';
import Uploader from '@/components/share/layouts/partial/Uploader';
import ticket from '@/api/prod/ticket';
import constants from '@/lib/constants';
import utils from '@/lib/utils';
import { ACTION_GET_TICKET_TOPIC_CATEGORIES, ACTION_GET_TICKET_TOPICS, ACTION_TRIGGER_POPUP } from '@/store/action-types';
import common from '@/utils/common';

export default {
  name: 'Ticket',
  beforeRouteLeave(to, from, next) {
    if (this.isShowingConfirmLeaving || this.hasPopup || this.isSubmitting || to.name === 'NotFound') {
      next();
    } else {
      this.openConfirmLeaving();
      if (!this.isGoingPreviousPage) {
        this.nextRoute = to;
      }
      next(false);
    }
  },
  components: {
    VueCountdown,
    CustomCheckbox,
    Navigation,
    Breadcrumb,
    CustomInput,
    CustomRadio,
    CustomTextarea,
    CustomSelect,
    Confirm,
    Uploader,
  },
  data() {
    return {
      constants,
      utils,
      isShowingConfirmLeaving: false,
      isGoingRefreshPage: false,
      nextRoute: null,
      gameCodeOnUrl: this.$route.params.gameCode,
      baseCategoryNameOnUrl: this.$route.query.bc,
      ticketTopicCategoryIdOnUrl: parseInt(this.$route.query.ttc, 10) || null,
      hasChosenTicketTopicCategory: false,
      userCheckedBaseCategoryId: null,
      userCheckedTicketTopicCategoryId: null,
      userSelectedTicketTopicId: null,
      isEmailRequired: false,
      isTosChecked: false,
      errors: null,
      uploadingFiles: {},
      fieldsValue: {},
      emailAddress: null,
      requiredFields: [],
      isCoolingDown: false,
      isSubmitting: false,
    };
  },
  computed: {
    isTicketTopicCategoryDataReady() {
      return (
        !utils.isEmptyObject(this.gamesCodeNameMapping) &&
        !utils.isEmptyObject(this.gamesCodeIdMapping) &&
        !utils.isEmptyObject(this.baseCategoriesCodeIdMapping) &&
        !utils.isEmptyObject(this.baseCategoriesIdMapping) &&
        !utils.isEmptyObject(this.baseCategoriesIdTicketTopicCategoriesMapping) &&
        !utils.isEmptyObject(this.ticketTopicCategoryIdMapping)
      );
    },
    baseCategoriesToDisplay() {
      return this.baseCategories.filter((bc) => this.baseCategoriesIdTicketTopicCategoriesMapping[bc.id]);
    },
    currentGameName() {
      return this.gamesCodeNameMapping[this.gameCodeOnUrl] || '';
    },
    currentGameId() {
      return this.gamesCodeIdMapping[this.gameCodeOnUrl] || 0;
    },
    currentBaseCategoryName() {
      return this.userCheckedBaseCategoryId ? this.baseCategoriesIdMapping[this.userCheckedBaseCategoryId].name || '' : '';
    },
    currentTicketTopicCategories() {
      return this.baseCategoriesIdTicketTopicCategoriesMapping[this.userCheckedBaseCategoryId] || [];
    },
    currentTicketTopicCategoryName() {
      return this.userCheckedTicketTopicCategoryId
        ? this.ticketTopicCategoryIdMapping[this.userCheckedTicketTopicCategoryId].name || ''
        : '';
    },
    currentTicketTopicFields() {
      return this.userSelectedTicketTopicId ? this.ticketTopicIdMapping[this.userSelectedTicketTopicId].fields : [] || [];
    },
    isFirstButtonEnabled() {
      return this.userCheckedBaseCategoryId && this.userCheckedTicketTopicCategoryId && !this.isCoolingDown;
    },
    isSecondButtonEnabled() {
      return (
        this.userSelectedTicketTopicId &&
        this.isTosChecked &&
        this.isFormFilled &&
        this.isEmailFilledIfRequired &&
        !this.isUploading &&
        !this.isSubmitting
      );
    },
    isUploading() {
      return !utils.isEmptyObject(this.uploadingFiles) && Object.values(this.uploadingFiles).some((file) => !file.uploadCompleted);
    },
    isFormFilled() {
      // check all required fields are filled
      return this.requiredFields.every((field) => this.fieldsValue[field] && this.fieldsValue[field].length > 0);
    },
    isEmailFilledIfRequired() {
      return !(!this.utils.isValidEmail(this.emailAddress) && this.isEmailRequired);
    },
    isValidIfNull() {
      return !this.isTosChecked;
    },
    paths() {
      return [
        {
          id: 1,
          name: 'Ticket',
          params: { gameCode: this.gameCodeOnUrl },
          title: this.$t('TICKET__TITLE'),
        },
        {
          id: 2,
          name: '',
          params: {},
          title: this.currentGameName,
        },
      ];
    },
    mainBgImage() {
      return {
        'background-image': `url(${process.env.CDN}/games/${this.gameCodeOnUrl}/bg-main-${this.gameCodeOnUrl}.jpg)`,
      };
    },
    storageKey() {
      return `support_${Date.now()}`;
    },
    openerDomain() {
      const testPrefix = 'test-';
      const isTestEnv = !window.location.hostname.indexOf(testPrefix);
      return `https://${isTestEnv ? testPrefix : ''}myinfo.support.garena.${process.env.REGION}`;
    },
    curGame() {
      const pathParts = window.location.pathname.split('/');
      return pathParts[pathParts.length - 1];
    },
    canInjectKeys() {
      if (process.env.REGION !== 'tw') {
        return [];
      }
      return ['garena_open_id', 'character_name', 'server', 'uid'];
    },
    shouldShowMyinfo() {
      return this.canInjectKeys.some((k) => {
        return common.hasOwn(this.fieldsValue, k);
      });
    },
    ...mapState({
      games: (state) => state.game.games,
      baseCategories: (state) => state.category.baseCategories,
      ticketTopicCategories: (state) => state.category.ticketTopicCategories,
      ticketTopics: (state) => state.ticketTopics,
    }),
    ...mapGetters([
      'gamesCodeNameMapping',
      'gamesCodeIdMapping',
      'baseCategoriesCodeIdMapping',
      'baseCategoriesIdMapping',
      'baseCategoriesIdTicketTopicCategoriesMapping',
      'ticketTopicCategoryIdMapping',
      'ticketTopicIdMapping',
    ]),
  },
  methods: {
    handleCooldownEnd() {
      this.isCoolingDown = false;
    },
    handleCountdownEnd() {
      this.isSubmitting = false;
    },
    openConfirmLeaving() {
      this.isShowingConfirmLeaving = true;
    },
    closeConfirmLeaving() {
      this.isShowingConfirmLeaving = false;
    },
    toPrevious() {
      this.$router.go(-1);
    },
    toNext() {
      const { name, params } = this.nextRoute;
      this.$router.push({ name, params });
    },
    changePage() {
      if (this.isGoingPreviousPage) {
        this.isGoingPreviousPage = false;
        this.toPrevious();
      } else {
        this.toNext();
      }
    },
    checkTicketNotThrottling() {
      return ticket.getTicketThrottling(this.currentGameId, this.userCheckedBaseCategoryId).then((resp) => {
        const { error } = resp;
        return !error;
      });
    },
    lockTicketTopicCategoryId() {
      this.checkTicketNotThrottling().then((resp) => {
        if (resp) {
          this.hasChosenTicketTopicCategory = true;
        } else {
          this.isCoolingDown = true;
          this.triggerPopup({
            title: this.$t('POPUP__ALERT'),
            content: this.$t('POPUP__ERROR_TICKET_THROTTLING'),
          });
        }
      });
    },
    updateUploadingFiles(files) {
      this.uploadingFiles = files;
    },
    addUploadedFileIds(name, fileId) {
      if (!this.fieldsValue[name]) {
        this.fieldsValue[name] = [];
      }
      this.fieldsValue[name].push(fileId);
    },
    removeUploadedFileIds(name, fileId) {
      const idx = this.fieldsValue[name].indexOf(fileId);
      this.fieldsValue[name].splice(idx, 1);
    },
    submitTicket() {
      this.isSubmitting = true;
      if (utils.getCookie('ticket_throttling')) {
        this.triggerPopup({
          title: this.$t('POPUP__ERROR'),
          content: this.$t('POPUP__ERROR_TICKET_THROTTLING'),
        });
        return;
      }

      const body = {
        ...this.fieldsValue,
      };

      if (this.partition_id) {
        body.server = this.partition_id;
      }

      const payload = {
        game_id: this.currentGameId,
        category_id: this.userCheckedBaseCategoryId,
        ticket_topic_category_id: this.userCheckedTicketTopicCategoryId,
        ticket_topic_id: this.userSelectedTicketTopicId,
        need_notification_email: this.isEmailRequired,
        body: JSON.stringify(body),
      };
      if (this.isEmailRequired) {
        payload.notification_email_address = this.emailAddress;
      }
      ticket
        .createTicket(payload)
        .then((resp) => {
          const { data, error } = resp;
          if (error || !data) {
            if (error === 'error_ticket_throttling') {
              this.triggerPopup({
                title: this.$t('POPUP__ERROR'),
                content: this.$t('POPUP__ERROR_TICKET_THROTTLING'),
              });
            } else {
              this.triggerPopup({
                title: this.$t('POPUP__ERROR'),
                content: this.$t('POPUP__ERROR_TICKET_CREATE_FAILED'),
              });
              this.isSubmitting = false;
            }
          } else {
            let popupContent = this.$t('POPUP__ALERT_TICKET_CREATE_SUCCEED_CONTENT', { csNumber: data.csNumber });
            if (this.isTwVersion) {
              popupContent += `<br>${this.$t('GENERAL__SERVICE_PHONE')}<br>${this.$t('GENERAL__SERVICE_TIME')}`;
            }
            this.triggerPopup({
              title: this.$t('POPUP__ALERT_TICKET_CREATE_SUCCEED_TITLE', { csNumber: data.csNumber }),
              content: popupContent,
            });
            utils.setCookie('ticket_throttling', 1, 60);
            this.$router.push({ name: 'Report', params: { csNumber: data.csNumber } });
          }
        })
        .catch(() => {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_TICKET_CREATE_FAILED'),
          });
        });
    },
    getMyinfo() {
      // todo: remove test query
      const openerUrl = `${this.openerDomain}?game=${this.curGame}&skey=${this.storageKey}`;
      this.opener = window.open(openerUrl);
    },
    injectFieldByStorage({ origin, data }) {
      if (origin !== this.openerDomain || data.skey !== this.storageKey) {
        return;
      }

      if (this.opener) {
        this.opener.close();
      }

      this.canInjectKeys.forEach((k) => {
        if (common.hasOwn(data, k) && common.hasOwn(this.fieldsValue, k)) {
          this.fieldsValue[k] = data[k];
        }
      });

      const partitionIdKey = 'partition_id';
      if (common.hasOwn(this.fieldsValue, 'server') && common.hasOwn(data, partitionIdKey)) {
        this[partitionIdKey] = data[partitionIdKey];
      }
    },
    ...mapActions({
      getTicketTopicCategories: ACTION_GET_TICKET_TOPIC_CATEGORIES,
      getTicketTopics: ACTION_GET_TICKET_TOPICS,
      triggerPopup: ACTION_TRIGGER_POPUP,
    }),
  },
  created() {
    this.opener = null;
    this.partition_id = '';
  },
  mounted() {
    window.onpopstate = () => {
      this.isGoingPreviousPage = true;
    };
    window.addEventListener('message', this.injectFieldByStorage);
  },
  beforeDestory() {
    window.removeEventListener('message', this.injectFieldByStorage);
  },
  watch: {
    currentGameId: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.getTicketTopicCategories(this.currentGameId);
        }
      },
    },
    gamesCodeIdMapping: {
      immediate: true,
      handler(newValue) {
        if (!utils.isEmptyObject(newValue) && this.currentGameId === 0) {
          this.$router.push({ name: 'NotFound' });
        }
      },
    },
    isTicketTopicCategoryDataReady: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.userCheckedBaseCategoryId = this.baseCategoriesCodeIdMapping[this.baseCategoryNameOnUrl];
          if (!(this.userCheckedBaseCategoryId in this.baseCategoriesIdTicketTopicCategoriesMapping)) {
            this.userCheckedBaseCategoryId = null;
          }
          if (this.ticketTopicCategoryIdOnUrl && this.ticketTopicCategoryIdMapping[this.ticketTopicCategoryIdOnUrl]) {
            this.userCheckedTicketTopicCategoryId = this.ticketTopicCategoryIdOnUrl;
            if (this.ticketTopicCategoryIdMapping[this.ticketTopicCategoryIdOnUrl].baseCategoryId !== this.userCheckedBaseCategoryId) {
              this.userCheckedTicketTopicCategoryId = null;
            }
          }
          if (this.userCheckedBaseCategoryId && this.userCheckedTicketTopicCategoryId) {
            this.lockTicketTopicCategoryId();
          }
        }
      },
    },
    userCheckedBaseCategoryId(newValue) {
      if (newValue && this.userCheckedTicketTopicCategoryId) {
        if (this.ticketTopicCategoryIdMapping[this.userCheckedTicketTopicCategoryId].baseCategoryId !== newValue) {
          this.userCheckedTicketTopicCategoryId = null;
        }
      }
    },
    hasChosenTicketTopicCategory(newValue) {
      if (newValue) {
        this.getTicketTopics(this.userCheckedTicketTopicCategoryId);
      }
    },
    currentTicketTopicFields(newValue) {
      const newFieldsValue = {};
      this.requiredFields = [];
      newValue.forEach((field) => {
        newFieldsValue[field.name] = null;
        if (field.isRequired) {
          this.requiredFields.push(field.name);
        }
      });
      this.fieldsValue = Object.assign({}, newFieldsValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.pc {
  .ttc-radios {
    flex-wrap: wrap;
  }
  .report-form__label span {
    color: $general-red;
  }
}

.myinfo {
  margin: 0 0 10px;
}
</style>
