var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-wrap main-wrap--game" },
    [
      _c("div", { staticClass: "main-bg", style: _vm.mainBgImage }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c("navigation", {
            attrs: { title: _vm.$t("TICKET__TITLE"), "is-game-page": true },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "main-inner" }, [
            _c(
              "div",
              { staticClass: "report" },
              [
                _c("breadcrumb", { attrs: { paths: _vm.paths } }),
                _vm._v(" "),
                _c("div", { staticClass: "main-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("TICKET__TITLE")) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "report-main" }, [
                  _c("dl", { staticClass: "report-warning" }, [
                    _c("dt", { staticClass: "report-warning__title" }, [
                      _vm._v(_vm._s(_vm.$t("TICKET__NOTICE"))),
                    ]),
                    _vm._v(" "),
                    _c("dd", [
                      _c("ol", { staticClass: "report-warning__list" }, [
                        _c("li", {
                          staticClass: "report-warning__item",
                          domProps: {
                            innerHTML: _vm._s(_vm.$t("TICKET__NOTICE_1")),
                          },
                        }),
                        _vm._v(" "),
                        _c("li", {
                          staticClass: "report-warning__item",
                          domProps: {
                            innerHTML: _vm._s(_vm.$t("TICKET__NOTICE_2")),
                          },
                        }),
                        _vm._v(" "),
                        _c("li", {
                          staticClass: "report-warning__item",
                          domProps: {
                            innerHTML: _vm._s(_vm.$t("TICKET__NOTICE_3")),
                          },
                        }),
                        _vm._v(" "),
                        _c("li", {
                          staticClass: "report-warning__item",
                          domProps: {
                            innerHTML: _vm._s(_vm.$t("TICKET__NOTICE_4")),
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "form",
                    { staticClass: "report-form" },
                    [
                      _c("p", { staticClass: "report-form__tip" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("TICKET__REMINDER")) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.isTicketTopicCategoryDataReady
                        ? [
                            !_vm.hasChosenTicketTopicCategory
                              ? [
                                  _c(
                                    "ol",
                                    { staticClass: "report-form__ol" },
                                    [
                                      _c(
                                        "li",
                                        { staticClass: "report-form__row" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "report-form__label",
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t("TICKET__QUESTION_1")
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-form__radios",
                                            },
                                            _vm._l(
                                              _vm.baseCategoriesToDisplay,
                                              function (category) {
                                                return _c("custom-radio", {
                                                  key: category.id,
                                                  attrs: {
                                                    label: category.name,
                                                    name: "base_category",
                                                    "radio-value": category.id,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.userCheckedBaseCategoryId,
                                                    callback: function ($$v) {
                                                      _vm.userCheckedBaseCategoryId =
                                                        $$v
                                                    },
                                                    expression:
                                                      "userCheckedBaseCategoryId",
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.userCheckedBaseCategoryId
                                        ? [
                                            _c(
                                              "li",
                                              {
                                                staticClass: "report-form__row",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "report-form__label",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "TICKET__QUESTION_2"
                                                          )
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "report-form__radios ttc-radios",
                                                  },
                                                  _vm._l(
                                                    _vm.currentTicketTopicCategories,
                                                    function (ttc) {
                                                      return _c(
                                                        "custom-radio",
                                                        {
                                                          key: ttc.id,
                                                          attrs: {
                                                            label: ttc.name,
                                                            name: "ticket_topic_category",
                                                            "radio-value":
                                                              ttc.id,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.userCheckedTicketTopicCategoryId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.userCheckedTicketTopicCategoryId =
                                                                $$v
                                                            },
                                                            expression:
                                                              "userCheckedTicketTopicCategoryId",
                                                          },
                                                        }
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "report-form__divider",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "report-form__next" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "rountbutton" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "rountbutton__widget",
                                              attrs: {
                                                disabled:
                                                  !_vm.isFirstButtonEnabled,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.lockTicketTopicCategoryId.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "GENERAL__BUTTON_NEXT_STEP"
                                                    )
                                                  ) +
                                                  "\n                      "
                                              ),
                                              _vm.isCoolingDown
                                                ? _c("vue-countdown", {
                                                    attrs: { time: 10000 },
                                                    on: {
                                                      end: _vm.handleCooldownEnd,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (props) {
                                                            return [
                                                              _vm._v(
                                                                "(" +
                                                                  _vm._s(
                                                                    props.totalSeconds
                                                                  ) +
                                                                  ")"
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      1521751193
                                                    ),
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              : [
                                  _c(
                                    "div",
                                    { staticClass: "report-form__row" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "report-form__inline" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "report-form__label",
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t("TICKET__FIELD_GAME")
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "report-form__field",
                                            },
                                            [
                                              _c("custom-input", {
                                                attrs: {
                                                  value: _vm.currentGameName,
                                                  isDisabled: true,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "report-form__row" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "report-form__inline" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "report-form__label",
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "TICKET__FIELD_BASE_CATEGORY"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "report-form__field",
                                            },
                                            [
                                              _c("custom-input", {
                                                attrs: {
                                                  value:
                                                    _vm.currentBaseCategoryName,
                                                  isDisabled: true,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "report-form__row" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "report-form__inline" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "report-form__label",
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "TICKET__FIELD_TICKET_TOPIC_CATEGORY"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "report-form__field",
                                            },
                                            [
                                              _c("custom-input", {
                                                attrs: {
                                                  value:
                                                    _vm.currentTicketTopicCategoryName,
                                                  isDisabled: true,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "report-form__row" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "report-form__inline" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "report-form__label",
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "TICKET__FIELD_TICKET_TOPIC"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "report-form__field",
                                            },
                                            [
                                              _c("custom-select", {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "GENERAL__PLACEHOLDER_DEFAULT_SELECT"
                                                  ),
                                                  options:
                                                    _vm.ticketTopicIdMapping,
                                                },
                                                model: {
                                                  value:
                                                    _vm.userSelectedTicketTopicId,
                                                  callback: function ($$v) {
                                                    _vm.userSelectedTicketTopicId =
                                                      $$v
                                                  },
                                                  expression:
                                                    "userSelectedTicketTopicId",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.userSelectedTicketTopicId
                                    ? [
                                        _vm.shouldShowMyinfo
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-form__row form__inline",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "report-form__inline",
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "report-form__label",
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "report-form__field",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "report-form__next",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "rountbutton",
                                                              },
                                                              [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "rountbutton__widget",
                                                                    attrs: {
                                                                      type: "button",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.getMyinfo,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                              " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "GENERAL__BUTTON_MYINFO"
                                                                          )
                                                                        ) +
                                                                        "\n                            "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.currentTicketTopicFields,
                                          function (field) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  key: field.id,
                                                  staticClass:
                                                    "report-form__row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "report-form__inline",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "report-form__label",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                field.displayName
                                                              ) +
                                                              "\n                          "
                                                          ),
                                                          field.isRequired
                                                            ? _c("span", [
                                                                _vm._v("*"),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "report-form__field",
                                                        },
                                                        [
                                                          field.type === "input"
                                                            ? [
                                                                _c(
                                                                  "custom-input",
                                                                  {
                                                                    attrs: {
                                                                      name: field.name,
                                                                      placeholder:
                                                                        field
                                                                          .attributes
                                                                          .placeholder,
                                                                      "is-required":
                                                                        field.isRequired,
                                                                      "error-msg":
                                                                        _vm.$t(
                                                                          "GENERAL__INPUT_ERROR_REQUIRED"
                                                                        ),
                                                                      "is-valid-if-null":
                                                                        _vm.isValidIfNull,
                                                                      "is-limit-length": true,
                                                                      readonly:
                                                                        _vm.canInjectKeys.indexOf(
                                                                          field.name
                                                                        ) >= 0,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .fieldsValue[
                                                                          field
                                                                            .name
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.fieldsValue,
                                                                            field.name,
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "fieldsValue[field.name]",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            : field.type ===
                                                              "textarea"
                                                            ? [
                                                                _c(
                                                                  "custom-textarea",
                                                                  {
                                                                    attrs: {
                                                                      name: field.name,
                                                                      placeholder:
                                                                        field
                                                                          .attributes
                                                                          .placeholder,
                                                                      "is-required":
                                                                        field.isRequired,
                                                                      "error-msg":
                                                                        _vm.$t(
                                                                          "GENERAL__INPUT_ERROR_REQUIRED"
                                                                        ),
                                                                      "is-valid-if-null":
                                                                        _vm.isValidIfNull,
                                                                      "is-limit-length": true,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .fieldsValue[
                                                                          field
                                                                            .name
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.fieldsValue,
                                                                            field.name,
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "fieldsValue[field.name]",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _vm.errors &&
                                                          _vm.errors[field.name]
                                                            ? [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "report-form__error",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        field
                                                                          .attributes
                                                                          .error
                                                                      ) + "。"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          field.type === "file"
                                                            ? [
                                                                _c("uploader", {
                                                                  attrs: {
                                                                    name: field.name,
                                                                    label:
                                                                      _vm.$t(
                                                                        "GENERAL__BUTTON_UPLOAD_FILE"
                                                                      ),
                                                                    "is-required":
                                                                      field.isRequired,
                                                                    "error-msg":
                                                                      _vm.$t(
                                                                        "GENERAL__INPUT_ERROR_REQUIRED"
                                                                      ),
                                                                    "is-valid-if-null":
                                                                      _vm.isValidIfNull,
                                                                  },
                                                                  on: {
                                                                    uploading:
                                                                      _vm.updateUploadingFiles,
                                                                    uploaded:
                                                                      _vm.addUploadedFileIds,
                                                                    removed:
                                                                      _vm.removeUploadedFileIds,
                                                                  },
                                                                }),
                                                              ]
                                                            : _vm._e(),
                                                        ],
                                                        2
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          }
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "report-form__divider",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "report-form__row" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "report-form__inline" },
                                        [
                                          _c("div", {
                                            staticClass: "report-form__label",
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "report-form__field",
                                            },
                                            [
                                              _c("custom-checkbox", {
                                                attrs: {
                                                  name: "ticket_email_checkbox",
                                                  label: _vm.$t(
                                                    "TICKET__EMAIL_FIELD_CHECKBOX"
                                                  ),
                                                },
                                                model: {
                                                  value: _vm.isEmailRequired,
                                                  callback: function ($$v) {
                                                    _vm.isEmailRequired = $$v
                                                  },
                                                  expression: "isEmailRequired",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.isEmailRequired
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "report-form__row" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-form__inline",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "report-form__label",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "TICKET__EMAIL"
                                                          )
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "report-form__field",
                                                  },
                                                  [
                                                    _c("custom-input", {
                                                      attrs: {
                                                        name: "notification_email_address",
                                                        placeholder: _vm.$t(
                                                          "GENERAL__INPUT_PLACEHOLDER_HALFWIDTH"
                                                        ),
                                                        "custom-type":
                                                          _vm.constants
                                                            .GENERAL__INPUT_TYPES
                                                            .EMAIL,
                                                        "error-msg": _vm.$t(
                                                          "GENERAL__INPUT_ERROR_EMAIL_FORMAT"
                                                        ),
                                                      },
                                                      model: {
                                                        value: _vm.emailAddress,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.emailAddress = $$v
                                                        },
                                                        expression:
                                                          "emailAddress",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "report-form__row" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "report-form__inline" },
                                        [
                                          _c("div", {
                                            staticClass: "report-form__label",
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "report-form__field",
                                            },
                                            [
                                              _c("custom-checkbox", {
                                                attrs: {
                                                  name: "ticket_tos_checkbox",
                                                  label: _vm.$t(
                                                    "TICKET__TOS_CHECKBOX"
                                                  ),
                                                },
                                                model: {
                                                  value: _vm.isTosChecked,
                                                  callback: function ($$v) {
                                                    _vm.isTosChecked = $$v
                                                  },
                                                  expression: "isTosChecked",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "report-form__error",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "TICKET__TOS_NOTICE"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "report-form__next report-form__next2",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "rountbutton",
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "rountbutton__widget",
                                                          attrs: {
                                                            disabled:
                                                              !_vm.isSecondButtonEnabled,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.submitTicket.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "GENERAL__BUTTON_SUBMIT_TICKET"
                                                                )
                                                              ) +
                                                              "\n                            "
                                                          ),
                                                          _vm.isSubmitting
                                                            ? _c(
                                                                "vue-countdown",
                                                                {
                                                                  attrs: {
                                                                    time: 10000,
                                                                  },
                                                                  on: {
                                                                    end: _vm.handleCountdownEnd,
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "default",
                                                                          fn: function (
                                                                            props
                                                                          ) {
                                                                            return [
                                                                              _vm._v(
                                                                                "(" +
                                                                                  _vm._s(
                                                                                    props.totalSeconds
                                                                                  ) +
                                                                                  ")"
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      1521751193
                                                                    ),
                                                                }
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.isTosChecked &&
                                                      !_vm.isSecondButtonEnabled &&
                                                      !_vm.isSubmitting
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "report-form__error",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "TICKET__SUBMIT_NOTICE"
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                          ]
                        : [
                            _c("div", { staticClass: "loading" }, [
                              _c("img", {
                                staticClass: "icon-loading",
                                attrs: {
                                  src: `${_vm.CDN}/assets/pc/img/common/loading.gif`,
                                  alt: "",
                                },
                              }),
                            ]),
                          ],
                    ],
                    2
                  ),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isShowingConfirmLeaving
        ? _c("confirm", {
            attrs: {
              title: _vm.$t("POPUP__ALERT"),
              content: _vm.$t("POPUP__CONFIRM_LEAVE"),
            },
            on: { close: _vm.closeConfirmLeaving, confirm: _vm.changePage },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }