import { render, staticRenderFns } from "./Master.vue?vue&type=template&id=3ca09da2&"
import script from "./Master.vue?vue&type=script&lang=js&"
export * from "./Master.vue?vue&type=script&lang=js&"
import style0 from "../../styles/reset.scss?vue&type=style&index=0&lang=css&"
import style1 from "./Master.vue?vue&type=style&index=1&id=3ca09da2&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/support.garena.all/frontend/main/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3ca09da2')) {
      api.createRecord('3ca09da2', component.options)
    } else {
      api.reload('3ca09da2', component.options)
    }
    module.hot.accept("./Master.vue?vue&type=template&id=3ca09da2&", function () {
      api.rerender('3ca09da2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/mobile/Master.vue"
export default component.exports