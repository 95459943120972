var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "breadcrumb",
      class: { "breadcrumb--smpd": _vm.smpd, "breadcrumb--bd": _vm.hasBorder },
    },
    [
      _c(
        "router-link",
        {
          staticClass: "breadcrumb__item breadcrumb__root",
          attrs: { to: { name: "Home" } },
        },
        [
          _c("div", { staticClass: "breadcrumb__root-icon" }),
          _vm._v(" "),
          _c("div", { staticClass: "breadcrumb__root-text" }, [
            _vm._v(_vm._s(_vm.$t("GENERAL__TITLE"))),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.paths, function (path) {
        return [
          _c("div", {
            key: path.id * -1,
            staticClass: "breadcrumb__separator",
          }),
          _vm._v(" "),
          path.name.length > 0
            ? [
                _c(
                  "router-link",
                  {
                    key: path.id,
                    staticClass: "breadcrumb__item",
                    attrs: { to: { name: path.name, params: path.params } },
                  },
                  [_vm._v("\n        " + _vm._s(path.title) + "\n      ")]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          path.name.length === 0
            ? [
                _c(
                  "a",
                  {
                    key: path.id,
                    staticClass: "breadcrumb__item",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [_vm._v("\n        " + _vm._s(path.title) + "\n      ")]
                ),
              ]
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }