var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "step" }, [
    _c("div", { class: ["step-" + _vm.step + "-img"] }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }