var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "md-radio checkbox-wrap" }, [
    _c("input", {
      staticClass: "checkbox__widget",
      attrs: {
        type: "checkbox",
        id: _vm.name,
        name: _vm.name,
        disabled: _vm.isDisabled,
      },
      domProps: { checked: _vm.val },
      on: {
        change: function ($event) {
          return _vm.$emit("input", $event.target.checked)
        },
      },
    }),
    _vm._v(" "),
    _c("label", {
      attrs: { for: _vm.name },
      domProps: { innerHTML: _vm._s(_vm.label) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }