var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPopup
    ? _c("div", { staticClass: "notification-wrap" }, [
        _c("div", { staticClass: "notification-dimmer" }, [
          _c("div", { staticClass: "notification notification--sm" }, [
            _c(
              "button",
              {
                staticClass: "rountbutton__widget",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.onClickButton.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("GENERAL__BUTTON_NOTIFICATION")))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "notification-inner" }, [
              _c("div", { staticClass: "notification__title" }, [
                _c("div", { staticClass: "notification__title-icon" }),
                _vm._v(" "),
                _c("div", { staticClass: "notification__title-txt" }, [
                  _vm._v(_vm._s(_vm.popupContent.title)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "notification__main" }, [
                _c("div", { staticClass: "notification__content" }, [
                  _c("div", { staticClass: "notification__content-txt" }, [
                    _c("p", {
                      domProps: { innerHTML: _vm._s(_vm.popupContent.content) },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }