var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-wrap" }, [
    _c("div", { staticClass: "main-bg main-bg-game", style: _vm.mainBgImage }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("navigation", {
          attrs: {
            title: _vm.currentGameName,
            "has-search-bar": true,
            "search-type": "game",
            "search-code": _vm.gameCodeOnUrl,
            "is-blank": true,
            "is-game-page": true,
          },
        }),
        _vm._v(" "),
        _vm.article
          ? [
              _c("section", { staticClass: "game-section" }, [
                _c(
                  "div",
                  { staticClass: "cproblem__main-content" },
                  [
                    _c("div", { staticClass: "cproblem__main" }, [
                      _c("div", { staticClass: "faqdetail-title" }, [
                        _c("div", { staticClass: "faqdetail__question" }, [
                          _vm._v(_vm._s(_vm.article.title)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "faqdetail__content" },
                      [
                        _c("div", {
                          staticClass: "ip-solution__txt",
                          domProps: { innerHTML: _vm._s(_vm.article.content) },
                        }),
                        _vm._v(" "),
                        !_vm.utils.isEmptyArray(_vm.article.tags)
                          ? [
                              _c("div", { staticClass: "faqdetail__tag" }, [
                                _c(
                                  "div",
                                  { staticClass: "faqdetail__statistics-item" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.$t("ARTICLE__TAGS")) +
                                        "\n                  "
                                    ),
                                    _vm._l(
                                      _vm.article.tags,
                                      function (tag, idx) {
                                        return _c(
                                          "a",
                                          {
                                            key: idx,
                                            staticClass:
                                              "faqdetail__statistics-tag",
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(tag) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "cproblem__main-grey" }),
                    _vm._v(" "),
                    !_vm.utils.isEmptyArray(_vm.article.relatedArticles)
                      ? [
                          _c("div", { staticClass: "faqdetail-title" }, [
                            _c("div", { staticClass: "faqdetail__question" }, [
                              _vm._v(
                                _vm._s(_vm.$t("ARTICLE__RELATED_ARTICLE"))
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "cproblem__main-list" },
                            _vm._l(
                              _vm.article.relatedArticles,
                              function (relatedArticle) {
                                return _c(
                                  "li",
                                  { key: relatedArticle.id },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "cproblem__main-item",
                                        attrs: {
                                          to: {
                                            name: "MobileArticleGeneral",
                                            params: { id: relatedArticle.id },
                                          },
                                        },
                                      },
                                      [
                                        _c("div", {
                                          staticClass:
                                            "cproblem__main-item--icon",
                                        }),
                                        _vm._v(" "),
                                        _c("p", [
                                          _vm._v(_vm._s(relatedArticle.title)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cproblem__main-entry" },
                  [
                    _vm.showAIHelp
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "cproblem__main-entry--item rectangle-button",
                            attrs: {
                              to: {
                                name: "MobileTicket",
                                params: { gameCode: _vm.gameCodeOnUrl },
                                query: { bc: _vm.baseCategoryNameOnUrl },
                              },
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "cproblem__main-entry--desc" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          `ARTICLE__TICKET_SUBTITLE__${_vm.gameCodeOnUrl.toUpperCase()}`
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "cproblem__main-entry--name" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          `ARTICLE__TICKET_TITLE__${_vm.gameCodeOnUrl.toUpperCase()}`
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "cproblem__main-entry--desc" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          `ARTICLE__TICKET_DESC__${_vm.gameCodeOnUrl.toUpperCase()}`
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", {
                              staticClass:
                                "cproblem__main-entry--icon cproblem__main-entry--createreport",
                            }),
                          ]
                        )
                      : _c(
                          "router-link",
                          {
                            staticClass:
                              "cproblem__main-entry--item rectangle-button",
                            attrs: {
                              to: {
                                name: "MobileTicket",
                                params: { gameCode: _vm.gameCodeOnUrl },
                                query: { bc: _vm.baseCategoryNameOnUrl },
                              },
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "cproblem__main-entry--desc" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("ARTICLE__TICKET_SUBTITLE")
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "cproblem__main-entry--name" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("ARTICLE__TICKET_TITLE")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "cproblem__main-entry--desc" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("ARTICLE__TICKET_DESC")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", {
                              staticClass:
                                "cproblem__main-entry--icon cproblem__main-entry--createreport",
                            }),
                          ]
                        ),
                  ],
                  1
                ),
              ]),
            ]
          : [
              _c("div", { staticClass: "loading" }, [
                _c("img", {
                  staticClass: "icon-loading",
                  attrs: {
                    src: `${_vm.CDN}/assets/pc/img/common/loading.gif`,
                    alt: "",
                  },
                }),
              ]),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }