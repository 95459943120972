var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-wrap main-wrap--hassearch" }, [
    _c("div", { staticClass: "main-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("navigation", {
          attrs: { title: _vm.$t("GENERAL__TITLE"), "has-search-bar": true },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "main-inner" }, [
          _c("div", { staticClass: "faqdetail-main" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("POPUP__ERROR_404")) + "\n      "
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }