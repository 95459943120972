var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chat__bottom" }, [
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.inputVal,
          expression: "inputVal",
        },
      ],
      ref: "chatInput",
      staticClass: "chat__bottom-input",
      attrs: {
        rows: "3",
        cols: "50",
        maxlength: "200",
        autofocus: "",
        placeholder: _vm.$t("AI_FAQ__CHAT_INPUT__PLACEHOLDER"),
        disabled: _vm.inputLocked,
      },
      domProps: { value: _vm.inputVal },
      on: {
        focus: _vm.focus,
        blur: _vm.blur,
        keydown: _vm.onKeydown,
        input: function ($event) {
          if ($event.target.composing) return
          _vm.inputVal = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _c("a", {
      staticClass: "chat__bottom-send",
      class: { disabled: _vm.isMessageLoading || !_vm.inputVal },
      on: { click: _vm.send },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }