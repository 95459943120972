var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "reportlist__detail-main" }, [
      _c("form", { staticClass: "report-form" }, [
        _c(
          "div",
          { staticClass: "report-form__content" },
          [
            _c("div", { staticClass: "report-form__label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("REPORT__CONTINUE_REPLY_TITLE")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("custom-textarea", {
              attrs: {
                name: "continue_reply_text",
                placeholder: _vm.textareaPlaceholder,
                "is-limit-length": true,
              },
              model: {
                value: _vm.fieldsValue.content,
                callback: function ($$v) {
                  _vm.$set(_vm.fieldsValue, "content", $$v)
                },
                expression: "fieldsValue.content",
              },
            }),
            _vm._v(" "),
            _c("uploader", {
              key: _vm.uploaderComponentKey,
              attrs: {
                label: _vm.$t("REPORT__CONTINUE_REPLY_UPLOAD_FILE"),
                name: "continue_reply_file",
              },
              on: {
                uploading: _vm.updateUploadingFiles,
                uploaded: _vm.addUploadedFileIds,
                removed: _vm.removeUploadedFileIds,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "report-form__content" },
          [
            _c("custom-checkbox", {
              attrs: {
                name: "feedback_email_checkbox",
                label: _vm.$t("REPORT__CONTINUE_REPLY_SEND_EMAIL"),
              },
              model: {
                value: _vm.isNewEmailRequired,
                callback: function ($$v) {
                  _vm.isNewEmailRequired = $$v
                },
                expression: "isNewEmailRequired",
              },
            }),
            _vm._v(" "),
            _vm.isNewEmailRequired
              ? [
                  _c("div", { staticClass: "report-form__row" }, [
                    _c("div", { staticClass: "report-form__inline" }, [
                      _c("div", { staticClass: "report-form__label" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$t("REPORT__CONTINUE_REPLY_CHANGE_EMAIL")
                            ) +
                            "\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "report-form__field" },
                        [
                          _c("custom-input", {
                            attrs: {
                              name: "notification_email_address",
                              placeholder: _vm.$t(
                                "GENERAL__INPUT_PLACEHOLDER_HALFWIDTH"
                              ),
                              "custom-type":
                                _vm.constants.GENERAL__INPUT_TYPES.EMAIL,
                              "error-msg": _vm.$t(
                                "GENERAL__INPUT_ERROR_EMAIL_FORMAT"
                              ),
                            },
                            model: {
                              value: _vm.newUserEmail,
                              callback: function ($$v) {
                                _vm.newUserEmail = $$v
                              },
                              expression: "newUserEmail",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "reportlist__return",
        attrs: { disabled: !_vm.isButtonEnabled },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.submitReply.apply(null, arguments)
          },
        },
      },
      [
        _vm._v(
          "\n    " + _vm._s(_vm.$t("GENERAL__BUTTON_SUBMIT_REPLY")) + "\n  "
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }