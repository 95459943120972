var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-wrap main-wrap--hassearch" },
    [
      _c("div", { staticClass: "main-bg" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c("navigation", {
            attrs: { title: _vm.$t("GENERAL__TITLE"), "has-search-bar": true },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "main-inner main-inner--nobg" }, [
            _c("div", { staticClass: "home" }, [
              _c(
                "div",
                { staticClass: "home-main" },
                [
                  _vm.isTwVersion
                    ? [
                        _c("section", { staticClass: "home-section" }, [
                          _c(
                            "div",
                            { staticClass: "home-entry" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "home-entry__item rectangle-button",
                                  attrs: { to: { name: "CantLogin" } },
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "home-entry__item-icon home-entry__item-icon--cantlogin",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "home-entry__item-content" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "home-entry__item-name",
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.$t("HOME__CANT_LOGIN_TITLE")
                                              ) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "home-entry__item-desc",
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.$t("HOME__CANT_LOGIN_DESC")
                                              ) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "home-entry__item rectangle-button",
                                  attrs: { to: { name: "Verification" } },
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "home-entry__item-icon home-entry__item-icon--accountsc",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "home-entry__item-content" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "home-entry__item-name",
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.$t(
                                                  "HOME__VERIFICATION_TITLE"
                                                )
                                              ) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "home-entry__item-desc",
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.$t(
                                                  "HOME__VERIFICATION_DESC"
                                                )
                                              ) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.games
                    ? [
                        _c("game-list", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.games.pc,
                              expression: "games.pc",
                            },
                          ],
                          attrs: {
                            games: _vm.games.pc ? _vm.games.pc : [],
                            gameType: "pc",
                          },
                        }),
                        _vm._v(" "),
                        _c("game-list", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.games.mobile,
                              expression: "games.mobile",
                            },
                          ],
                          attrs: {
                            games: _vm.games.mobile ? _vm.games.mobile : [],
                            gameType: "mobile",
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "home-aside" },
                [
                  _c("user"),
                  _vm._v(" "),
                  _vm.isTwVersion ? [_c("top-news")] : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("notification", { attrs: { pos: "home" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }